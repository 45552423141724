import React, { useState,useEffect } from "react";
import { Button, Row, Table, Modal } from "react-bootstrap";

import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import './Admin.css'
import axios from 'axios'



const Gallery = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

 // condition 
  const [image, setBanner] = useState(true);
  const [video, setGallery] = useState("");

  //post method

  const [galleryimg, setGalleryimg] = useState('')
  const [gallerytitle,setGallerytitle] = useState('')

  const AddGallery = async () => {
    const formdata = new FormData()
    formdata.append('galleryimg', galleryimg)
    formdata.append('gallerytitle', gallerytitle)

    if (!galleryimg ||  !gallerytitle) {
      alert("All fields are required");
      return;
    }
    try {
      const config = {
        url: "/postgalleryimg",
        method: "post",
        baseURL: "https://talharconstructions.in/api/gallery",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getData();
        setGallerytitle('');
        setGalleryimg('');
       }

    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }
    // get method
    const [data, setData] = useState([])
    const getData = async () => {
      try {
        let res = await axios.get("https://talharconstructions.in/api/gallery/getgalleryimg")
        if (res.status === 200) {
          setData(res.data.success)
        }
      } catch (error) {
        console.log(error)
      }
    }
  
    useEffect(() => {
      getData();
      
    }, [])

    //Delete method
    const deletData = async (_id) => {
      try {
        let res = await axios.delete(`https://talharconstructions.in/api/gallery/deletegalleryimg/${edit?._id}`)
        if (res.status === 200) {
          alert("Deleted Successfully")
          handleClose2()
          getData([])
        }
      } catch (error) {
        console.log(error)
      }
    }
    const [edit, setEdit] = useState({})
    console.log(edit, "sditeeee")
  
    //Update method
// console.log("edit",edit);
useEffect(() => {
  if (edit) {
    setGallerytitle(edit?.gallerytitle || "")
   
  }
}, [])
    
    const editBanner = async () => {
      const formdata = new FormData()
      formdata.append('galleryimg', galleryimg);
    formdata.append('gallerytitle', gallerytitle);

    if (!galleryimg &&  !gallerytitle) {
      alert("Please update any one field.");
      return;
    }
      try {
        const config = {
          url: `/updategalleryimg/${edit?._id}`,
          method: "put",
          baseURL: "https://talharconstructions.in/api/gallery",
          Headers: { "content-type": "multipart/form-data" },
          data: formdata,
        };
        let res = await axios(config)
        if (res.status === 200) {
          alert(res.data.success);
          handleClose1();
          getData();
          setGallerytitle('');
          setGalleryimg('');

        }
  
      } catch (error) {
        console.error('Error adding banner:', error);
        alert('Failed to add banner');
      }
    }

  // <....................Galler video................>
//post method
const [galleryvideo, setGalleryvideo] = useState('')
const [videotext,setVideotext] = useState('')
  
const AddGalleryVideo = async () => {

  const formdata = new FormData()
  formdata.append('galleryvideo', galleryvideo)
  formdata.append('videotext', videotext)
  
  if (!galleryvideo ||  !videotext) {
    alert("All fields are required");
    return;
  }

  try {
    const config = {
      url: "/postgalleryvideo",
      method: "post",
      baseURL: "https://talharconstructions.in/api/gallery",
      Headers: { "content-type": "multipart/form-data" },
      data: formdata,
    };
    let res = await axios(config)
    if (res.status === 200) {
      alert(res.data.success);
      handleClose();
      getData1();
      setVideotext('');
      setGalleryvideo('')
     }

  } catch (error) {
    console.error('Error adding banner:', error);
    alert('Failed to add banner');
  }
}


 // get method
 const[data1,setData1] = useState([])

const getData1 = async () => {
   try {
     let res = await axios.get("https://talharconstructions.in/api/gallery/getgalleryvideo")
     if (res.status === 200) {
      setData1(res.data.success)
     }
   } catch (error) {
     console.log(error)
   }
 }

 useEffect(() => {
   getData1();
  
 }, [])
 console.log(data1,"ojripejwpjop");

 //Update Method
// console.log("edit",edit);
 useEffect(() =>{
  if(edit){
    setVideotext(edit?.videotext || "")
  }
 }, [])
 const editGalleryVideo = async () => {

  const formdata = new FormData()
  formdata.append('galleryvideo', galleryvideo);
  formdata.append('videotext', videotext);

  if (!galleryvideo &&  !videotext) {
    alert("Please update any one field.");
    return;
  }
  try {
    const config = {
      url: `/updategalleryvideo/${edit?._id}`,
      method: "put",
      baseURL: "https://talharconstructions.in/api/gallery",
      Headers: { "content-type": "multipart/form-data" },
      data: formdata,
    };
    let res = await axios(config)
    if (res.status === 200) {
      alert(res.data.success);
      handleClose1();
      getData1();
      setVideotext('');
      setGalleryvideo('');
    }

  } catch (error) {
    console.error('Error adding banner:', error);
    alert('Failed to add banner');
  }
}

//Delete Method
const deletGalleryVideo = async (_id) => {
  try {
    let res = await axios.delete(`https://talharconstructions.in/api/gallery/deletegalleryvideo/${edit?._id}`)
    if (res.status === 200) {
      alert("Deleted Successfully")
      handleClose2()
      getData1([])
    }
  } catch (error) {
    console.log(error)
  }
}

  return (
    <div>
      <Row>
        <div className='p-3 d-flex gap-3'>
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }} onClick={() => {
            setBanner(true);
            setGallery(false);

          }}>Image</Button>
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }}
            onClick={() => {
              setBanner(false);
              setGallery(true);

            }}
          > Video</Button>
        </div>
      </Row>




      {image ? (<>
        <h1 className='h1-style'>IMAGE</h1>

        <Button className='add-buttons' onClick={handleShow}>
          +Add 
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Image </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class="mb-2">
              <label htmlFor="inputGroupFile03">Image</label>
              <div>
                <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload" 
                  acept="image/*"
                  onChange={(e) => setGalleryimg(e.target.files[0])} />
              </div>
            </div>

            <div class="mt-3">
              {/* <label htmlFor="">Title</label> */}
              <div>
                <input type="text"
                  placeholder='Title....'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={gallerytitle}
                  onChange={(e)=>setGallerytitle(e.target.value)}/>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddGallery}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              <h3 style={{ color: "green" }}>WARNING !!</h3>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deletData}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Image
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
             <div className='mb-2'>
             <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload" 
                  acept="image/*"
                  value={''}
                  onChange={(e) => setGalleryimg(e.target.files[0])} />
                </div>

            <div>
              {/* <label htmlFor="">title</label> */}
              <div>
              <input type="text"
                  placeholder='Title....'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={gallerytitle}
                  onChange={(e)=>setGallerytitle(e.target.value)}/>
              </div>
            </div>
             </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editBanner}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Sl.No</th>
              <th>Title</th>
                <th>Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data?.map((item, index) => {
            return (
              <tr key={item._id}>
                <td>{++index}.</td>
              <td>{item.gallerytitle}</td>
              <td>
                <div>
                  <img src={`https://talharconstructions.in/Galleryimgimage/${item?.galleryimg}`} 
                  alt="Galleryimgimage"
                  style={{ width: "100px", height: "100px" }} />
                </div>
                </td>
            
              <td>
              <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }} onClick={() => {
                    handleShow1();
                    setEdit(item)
                  }}
                  /> /
              <MdDelete style={{ cursor: 'pointer', color: "navy", fontSize: "30px" }} onClick={() => {
                    handleShow2();
                    setEdit(item)
                  }} />
              </td>
              </tr> 
            )
          })}
             
                </tbody>
          </Table>
        </div>
      </>) : (<></>)}

      {video ? (<>
        <h1 className='h1-style'>Video</h1>

        <Button className='add-buttons' onClick={handleShow}>
          +Add 
        </Button>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
        Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="mb-3">
              {/* <label htmlFor="inputGroupFile04">Video</label> */}
              <div>
              <input type="file"
                  class="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload" 
                  acept="video/*"
                  onChange={(e) => setGalleryvideo(e.target.files[0])} />
              </div>
            </div>
            <div>
              {/* <label htmlFor="">title</label> */}
              <div>
              <input type="text"
                  placeholder='Title....'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={videotext}
                  onChange={(e)=>setVideotext(e.target.value)}/>
              </div>
            </div>

           
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddGalleryVideo}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              <h3 style={{ color: "green" }}>WARNING !!</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deletGalleryVideo}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className='mb-2'>
            <label htmlFor="inputGroupFile04">video</label>
            <input type="file"
                  class="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload" 
                  acept="video/*"
                 
                  onChange={(e) => setGalleryvideo(e.target.files[0])} />
                  </div>

                 <div className="mb-2">
                   {/* <label htmlFor="">title</label> */}
                 <input type="text"
                  placeholder='Title....'
                  style={{ width: "100%" }} 
                  id="input-border"
                  value={videotext}
                  onChange={(e)=>setVideotext(e.target.value)}/>
                 </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editGalleryVideo}>
              Upadate
            </Button>
          </Modal.Footer>
        </Modal>
        <div>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Title</th>
                <th>video</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data1?.map((item, index) => {
            return (
              <tr key={item._id}>
                <td>{++index}.</td>
              <td>{item.videotext}</td>
              <td>
                <div>
                <video controls autoPlay muted style={{ width: "100px", height: "100px" }}>
    <source src={`https://talharconstructions.in/Galleryvideo/${item?.galleryvideo}`} type="video/mp4" />
 
  </video>

                </div>
                </td>
            
              <td>
              <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }} onClick={() => {
                    handleShow1();
                    setEdit(item)
                  }}
                  /> /
              <MdDelete style={{ cursor: 'pointer', color: "navy", fontSize: "30px" }} onClick={() => {
                    handleShow2();
                    setEdit(item)
                  }} />
              </td>
              </tr> 
            )
          })}
             
                </tbody>
          </Table>


        </div>
      </>) : (<></>)}

    </div>
  )
}

export default Gallery