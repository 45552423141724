import React, { useEffect, useState } from "react";
import { FaComputer } from "react-icons/fa";

import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import exportFromJSON from "export-from-json";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa"
import { FaSearch } from "react-icons/fa";


function Dashboard() {

  const [Slno, setSlno] = useState(1);
  const [data, setdata] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [editshow, seteditShow] = useState(false);
  const [editdata, seteditdata] = useState({});


  return (
    <div >
      
      <div className="row d-flex justify-content-center">
        <div className="col-md-6">
          <button className="dashboradbtn">
            <h4 > 
              <a style={{color:"white"}} href="/admin/services">Number Of Services</a>
              </h4>
            </button>
        </div>
        <div className="col-md-6">
          <button className="dashboradbtn">
            <h4 > 
              <a style={{color:"white"}} href="/packages">Number of Packages</a></h4>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;