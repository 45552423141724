import React, { useEffect, useState } from "react";
import "../Styles/About.css";

import { Container } from "react-bootstrap";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import parse from "html-react-parser";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Card from "react-bootstrap/Card";

import { ImQuotesLeft } from "react-icons/im";
import Aos from "aos";
import { FaSquareWhatsapp } from "react-icons/fa6";
import { GrUserExpert } from "react-icons/gr";
import { GiTimeTrap } from "react-icons/gi";
import Carousel from "react-bootstrap/Carousel";
import { TbListSearch } from "react-icons/tb";
import { MdMapsHomeWork } from "react-icons/md";
import { IoLogoDesignernews } from "react-icons/io5";
import { TbGitPullRequestClosed } from "react-icons/tb";
import axios from "axios";
// import parse from 'html-react-parser';
const ITEMS_PER_PAGE = 4;
const About = () => {
  const [activeTab, setActiveTab] = useState("mission");

  // Map of images based on active tab
  const images = {
    mission: "../Assets/aboutus1.jpg",
    vision: "../Assets/abt.jpg",
  };
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0);
  });

  const [ViewMore6, setViewMore6] = useState(false);

  const [ABoutUsDatat, setABoutUsDatat] = useState([]);
  const getAboutus = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getaboutusdetails"
      );
      if (res.status === 200) {
        setABoutUsDatat(res.data.status);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getAboutus();
  }, []);
  console.log("ABoutUsDatat", ABoutUsDatat);

  // const cleanedHtmlContent = ABoutUsDatat?.[0]?.description?.replace(
  //   /<\/?p>/g,
  //   ""
  // );
  // const cleanedHtmlContent1 = ABoutUsDatat?.[0]?.description1?.replace(
  //   /<\/?p>/g,
  //   ""
  // );
  // const cleanedHtmlContent2 = ABoutUsDatat?.[0]?.description2?.replace(
  //   /<\/?p>/g,
  //   ""
  // );
  // const cleanedHtmlContent3 = ABoutUsDatat?.[0]?.missionDescription?.replace(
  //   /<\/?p>/g,
  //   ""
  // );

  const cleanedHtmlContent4 = ABoutUsDatat?.[0]?.visionDescription?.replace(
    /<\/?p>/g,
    ""
  );

  const [ourteamImg, setOurTeam] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getTeam = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getourteamdetails"
      );
      if (res.status === 200) {
        setOurTeam(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  // Calculate the indices of the items to display based on the current page
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = ourteamImg.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(ourteamImg.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // console.log("our team", ourteamImg);

  // Why Choose Us??
  //get method

  const [whyChoose, setWhyChoose] = useState([]);

  const cleanedHtmlContent5 = whyChoose?.[0]?.chooseText?.replace(
    /<\/?p>/g,
    ""
  );

  // const htmlContent4 = whyChoose?.[0]?.chooseText;

  const getAchivement = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getachievementdetails"
      );
      if (res.status === 200) {
        setWhyChoose(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching achievement details:", error);
      alert("Failed to fetch achievement details");
    }
  };

  useEffect(() => {
    getAchivement();
  }, []);
  // const htmlContent3 = whyChoose?.[0]?.chooseText;
  console.log(whyChoose, "dhfwihfiwhi");

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
const [isDescription1Expanded, setIsDescription1Expanded] = useState(false);
const [isDescription2Expanded, setIsDescription2Expanded] = useState(false);
const [isMissionExpanded, setIsMissionExpanded] = useState(false);
const [isVisionExpanded, setIsVisionExpanded] = useState(false);

const CHAR_LIMIT = 200;
 // description
const description = ABoutUsDatat?.[0]?.description || "";
const truncatedDescription =
  description.length > CHAR_LIMIT
    ? `${description.slice(0, CHAR_LIMIT)}...`
    : description;

const contentToDisplay = isDescriptionExpanded ? description : truncatedDescription;

// description1
const description1 = ABoutUsDatat?.[0]?.description1 || "";
const truncatedDescription1 =
  description1.length > CHAR_LIMIT
    ? `${description1.slice(0, CHAR_LIMIT)}...`
    : description1;

const contentToDisplay1 = isDescription1Expanded ? description1 : truncatedDescription1;

// description2
const description2 = ABoutUsDatat?.[0]?.description2 || "";
const truncatedDescription2 =
  description2.length > CHAR_LIMIT
    ? `${description2.slice(0, CHAR_LIMIT)}...`
    : description2;

const contentToDisplay2 = isDescription2Expanded ? description2 : truncatedDescription2;

// mission Description
const missionDescription = ABoutUsDatat?.[0]?.missionDescription || "";
const truncatedDescription3 =
  missionDescription.length > CHAR_LIMIT
    ? `${missionDescription.slice(0, CHAR_LIMIT)}...`
    : missionDescription;

const contentToDisplay3 = isMissionExpanded ? missionDescription : truncatedDescription3;

// vision Description
const visionDescription = ABoutUsDatat?.[0]?.visionDescription || "";
const truncatedDescription4 =
  visionDescription.length > CHAR_LIMIT
    ? `${visionDescription.slice(0, CHAR_LIMIT)}...`
    : visionDescription;

const contentToDisplay4 = isVisionExpanded ? visionDescription : truncatedDescription4;

  return (
    <div>
      <div className="aboutus-container">
        <div className="image-wrapper">
          <img
            className="aboutus-img"
            src="../Assets/aboutus.jpg"
            alt="about us image"
          />
          <div className="text-overlay">
            <h1>About Us</h1>
          </div>
          <div className="link-about">
            <a className="home" href="/">
              Home
            </a>
            /<a href="#">About Us</a>
          </div>
        </div>
        <div className="fixed-icon">
          <a
            href="https://wa.me/8310330060" // Replace with the correct phone number
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat with us on WhatsApp"
          >
            <FaSquareWhatsapp style={{ fontSize: "30px", color: "white" }} />
          </a>
        </div>
      </div>

     {/* About Us Company.. */}
<Container>
  <div className="row me-0 mt-5 mb-5">
    <div className="col-md-5">
      <div data-aos="zoom-in" data-aos-duration="3000">
        <img
          className="construction"
          src={`https://talharconstructions.in/Aboutusimage/${ABoutUsDatat?.[0]?.aboutusimg}`}
          alt="contruction"
        />
      </div>
    </div>

    <div className="col-md-7">
      <h5 className="h5-txt">About Us </h5>
      <div className="row ">
        <div className="col-md-12">
          <h2 className="h2-txt">{ABoutUsDatat?.[0]?.aboutustitle}</h2>
          <p data-aos="zoom-in" data-aos-duration="3000">
            {parse(contentToDisplay)}
          </p>
          {description.length > CHAR_LIMIT && (
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            >
              {isDescriptionExpanded ? "View less" : "View More"}
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div
          className="col-md-6 "
          id="box-padding"
          data-aos="flip-right"
          data-aos-duration="3000"
        >
          <h4>{parse(`<div>${ABoutUsDatat?.[0]?.subTitle1}</div>`)} </h4>
          <p data-aos="zoom-in" data-aos-duration="3000">
            {parse(contentToDisplay1)}
          </p>
          {description1.length > CHAR_LIMIT && (
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setIsDescription1Expanded(!isDescription1Expanded)}
            >
              {isDescription1Expanded ? "View less" : "View More"}
            </div>
          )}
        </div>
        <div
          className="col-md-6 "
          id="box-padding"
          data-aos="flip-up"
          data-aos-duration="3000"
        >
          <h4> {ABoutUsDatat?.[0]?.subTitle2}</h4>
          <p data-aos="zoom-in" data-aos-duration="3000">
            {parse(contentToDisplay2)}
            {description2.length > CHAR_LIMIT && (
              <div
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => setIsDescription2Expanded(!isDescription2Expanded)}
              >
                {isDescription2Expanded ? "View less" : "View More"}
              </div>
            )}
          </p>
        </div>
      </div>
    </div>
  </div>
</Container>

{/* mission and vision Desription */}
<Container>
  <div className="row mt-5 mb-5">
    <div className="col-md-6">
      <div data-aos="fade-down" data-aos-duration="3000">
        <Tabs
          defaultActiveKey="mission"
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(k) => setActiveTab(k)} // Update active tab on selection
        >
          <Tab eventKey="mission" title="Mission">
            <div
              className="paragraph"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <h6>
                {parse(contentToDisplay3)}</h6>
                {missionDescription.length > CHAR_LIMIT && (
                  <div
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIsMissionExpanded(!isMissionExpanded)}
                  >
                    {isMissionExpanded ? "View less" : "View More"}
                  </div>
                )}
            </div>
          </Tab>

          <Tab eventKey="vision" title="Vision">
            <div
              className="paragraph"
              data-aos="fade-down"
              data-aos-duration="3000"
            >
              <h6>
                {parse(contentToDisplay4)}</h6>
                {visionDescription.length > CHAR_LIMIT && (
                  <div
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => setIsVisionExpanded(!isVisionExpanded)}
                  >
                    {isVisionExpanded ? "View less" : "View More"}
                  </div>
                )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
    <div className="col-md-5">
      <div data-aos="flip-right" data-aos-duration="2000">
        <img
          style={{ height: "auto", width: "100%", borderRadius: "10px" }}
          src={images[activeTab]} 
          alt={activeTab}
        />
      </div>
    </div>
  </div>
</Container>


      {/* our Team */}
      <Container>
        <h1 className="h1-txt">Our Team</h1>
        <div
          className="row mt-4 mb-3"
          data-aos="zoom-in"
          data-aos-duration="2000"
        >
          {currentItems.map((item) => (
            <div className="col-md-3 mb-2" key={item._id}>
              <div className="img-container">
                <img
                  className="imgg"
                  src={`https://talharconstructions.in/Ourteamimage/${item?.ourteamimg}`}
                  alt=""
                />
                <h3 className="img-caption">{item?.ourteamName}</h3>
                <p style={{ textAlign: "center" }}>
                  {" "}
                  <b>
                    {" "}
                    <span>Designation: </span>
                  </b>{" "}
                  {item?.designation}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination gap-3 d-flex justify-content-center">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </Container>

      {/* why Choose Us // instead of What client say*/}

      <section
        className="whychoose-bgblack"
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        <h4 style={{ color: "white" }}>--- Why Choose Us? --</h4>
        <h3 style={{ color: "white", textAlign: "center" }}>
          <b>Strength Is Construction, Power In Precision</b>{" "}
        </h3>
      </section>
      <Container>
        <div className="row me-0 mb-4 mt-2" id="white-bg">
          {whyChoose?.map((item) => {
            return (
              <div className="col-md-3 mb-4" id="card-styyle">
                <div>
                  <h5>{item?.chooseName}</h5>
                  <div>
                    <img
                      src={`https://talharconstructions.in/Aboutusimage/${item?.chooseImage}`}
                      alt={item?.chooseName}
                      style={{
                        borderRadius: "50%",
                        height: "100px",
                        width: "120px",
                      }}
                    />
                  </div>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div>${cleanedHtmlContent5}</div>`,
                      }}
                    />
                  </p>
                </div>
              </div>
            );
          })}

          <div className="pagination gap-3 d-flex justify-content-center">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </Container>

      {/* <div>
  <Container>
      <h1 className='h1-txt'>What Our Client Say?</h1>
        <Carousel className='mt-3 mb-3'>
          <Carousel.Item className='txt-carousel'>
            <p> "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, quisquam possimus. Pariatur fugit
              dolorum iste quaerat consectetur nobis eius, sunt repellendus error, vel nihil, vero voluptate illo
              quisquam culpa ab?Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, quisquam possimus. Pariatur fugit
              dolorum iste quaerat consectetur nobis eius, sunt repellendus error, vel nihil, vero voluptate illo
              quisquam culpa ab?"</p>
            <h4 className='client-name'>constructor</h4>
          </Carousel.Item>
          <Carousel.Item className='txt-carousel'>
            <p> "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, quisquam possimus. Pariatur fugit
              dolorum iste quaerat consectetur nobis eius, sunt repellendus error, vel nihil, vero voluptate illo
              quisquam culpa ab?Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, quisquam possimus. Pariatur fugit
              dolorum iste quaerat consectetur nobis eius, sunt repellendus error, vel nihil, vero voluptate illo
              quisquam culpa ab?"</p>
            <h4 className='client-name'>constructor</h4>
          </Carousel.Item>

          <Carousel.Item className='txt-carousel'>
            <p> "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, quisquam possimus. Pariatur fugit
              dolorum iste quaerat consectetur nobis eius, sunt repellendus error, vel nihil, vero voluptate illo
              quisquam culpa ab?Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore, quisquam possimus. Pariatur fugit
              dolorum iste quaerat consectetur nobis eius, sunt repellendus error, vel nihil, vero voluptate illo
              quisquam culpa ab?"</p>
            <h4 className='client-name'>constructor</h4>
          </Carousel.Item>
        </Carousel>
      </Container> 
</div> */}

      {/* How Process It Work */}
      <div
        style={{
          background: "rgba(211, 211, 211, 0.219)",
          padding: "15px",
          marginBottom: "20px",
        }}
      >
        <h6 className="h1-txt" style={{ marginTop: "10px" }}>
          // WORK PROCESS //
        </h6>
        <h1>HOW IT WORKS</h1>

        <div>
          <div
            className="row me-0 mt-2 mb-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-3 mb-2">
              <TbListSearch style={{ fontSize: "60px", color: "#4798bd" }} />
              <h2>Project Research</h2>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit
            </div>

            <div className="col-md-3 mb-2"></div>

            <div className="col-md-3 mb-2">
              <MdMapsHomeWork style={{ fontSize: "60px", color: "#4798bd" }} />
              <h2>Work Start</h2>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit
            </div>
            <div className="col-md-3 mb-2"></div>
          </div>
          <div className="row"></div>
        </div>
        <div>
          <div
            className="row me-0 mt-2 mb-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-3 mb-2"></div>

            <div className="col-md-3 mb-2">
              <img
                style={{ height: "40px", width: "50px", color: "#4798bd" }}
                src="../Assets/workprocess1.png"
                alt=""
              />
              {/* <IoLogoDesignernews style={{ fontSize: "100px", color: "#0ba1f8" }} /> */}
              <h2>Design Build</h2>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </div>
            <div className="col-md-3 mb-2"></div>

            <div className="col-md-3 mb-2">
              <TbGitPullRequestClosed
                style={{ fontSize: "60px", color: "#4798bd" }}
              />
              <h2>Finish Works</h2>
              Lorem ipsum, dolor sit amet consectetur
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
