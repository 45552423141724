import React from "react";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { TfiLayoutSlider } from "react-icons/tfi";
import { RxDashboard } from "react-icons/rx";
import { MdCategory } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";
import { RiServiceFill } from "react-icons/ri";
import { GiUnstableProjectile } from "react-icons/gi";
import { MdLocationSearching } from "react-icons/md";
import { GoPackageDependents } from "react-icons/go";
import { VscDashboard } from "react-icons/vsc";


function Sidebar1() {
  return (
    < ProSidebarProvider>
      <Sidebar>

     
      <Menu style={{ backgroundColor: "#4798bd" }}>
      <div style={{width:'auto', overflow:"hidden",height:"400px", overflowY:"scroll"}}>
        {/* <MenuItem
            component={<Link to="/admin/dashboard" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <VscDashboard style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Dashboard 
          </MenuItem> */}
          <MenuItem
            component={<Link to="/admin/banner" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <TfiLayoutSlider style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Banner
          </MenuItem>
          <MenuItem
            component={<Link to="/admin/webmanagement" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <RxDashboard style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Web Management
          </MenuItem>
          <MenuItem
            component={<Link to="/admin/services" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <MdCategory style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Services
          </MenuItem>

          <MenuItem
            component={<Link to="/admin/partners" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            
            <GiUnstableProjectile style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Latest Projects
          </MenuItem>

          <MenuItem
            component={<Link to="/admin/constructionPkackages" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <GoPackageDependents style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Construction Packages
          </MenuItem>
          <MenuItem
            component={<Link to="/admin/gallery" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <BiSolidOffer style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Gallery
          </MenuItem>
          <MenuItem
            component={<Link to="/admin/contactus" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <RiServiceFill style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Contact Us
          </MenuItem>
          <MenuItem
            component={<Link to="/admin/enquiries" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <MdLocationSearching style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Enquiries
          </MenuItem>
          
          {/* <MenuItem
            component={<Link to="/admin/packages" />}
            style={{ color: "black", fontWeight: "bold" }}
          >
            <GiThreeFriends style={{ margin: "0px 4px 0 0px", width: "25px", height: "20px" }} />
            Packages
          </MenuItem> */}

</div> 
        </Menu>

      
      
      </Sidebar>
    </ ProSidebarProvider>
  );
}

export default Sidebar1;
