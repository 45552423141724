import React, { useState, useEffect } from "react";
import { Button, Row, Table, Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import './Admin.css';
import { IoWarningOutline } from "react-icons/io5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import moment from 'moment'
import axios from 'axios'
import Pagination from 'react-bootstrap/Pagination'; // Import Pagination component

const WebManagement = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // condition 
  const [about, setAbout] = useState(true);
  const [Sevice, setSevice] = useState("");
  const [Choose, setChoose] = useState("");
  const [Numbers, setNumbers] = useState("")

  //Remove html tags From the table
  const stripHtmlTags = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  };

  // <---------------About Us ----------------->
  const [description, setdescription] = useState("");
  // const handleDescription = (e, editor) => {
  //   const data = editor.getData();
  //   setdescription(data);

  //   setDescription1(data1)
  //   setDescription2(data2)
  // };

  //Post tMethod
  const [image, setImage] = useState('')
  const [title, setTitle] = useState('')
  const [Description, setDescription] = useState('')
  const [subtitle1, setSubTitle1] = useState("")
  const [Description1, setDescription1] = useState("")
  const [subtitle2, setSubTitle2] = useState("")
  const [Description2, setDescription2] = useState("")
  const [vissionDescription, setVissionDescription] = useState("")
  const [missionDescription, setMissionDescription] = useState("")

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
    setClientDescription(data);
  };
  const handleDescriptionChange1 = (event, editor) => {
    const data = editor.getData();
    setDescription1(data);

  };
  const handleDescriptionChange2 = (event, editor) => {
    const data = editor.getData();
    setDescription2(data);
  };
  // const handleDescriptionChange3 = (event, editor) => {
  //   const data = editor.getData();
  //   setServicesDescription(data);

  // };
  const handleDescriptionChange4 = (event, editor) => {
    const data = editor.getData();
    setChooseText(data);

  };
  const AddAbout = async () => {

    const formdata = new FormData()

    formdata.append('aboutusimg', image);
    formdata.append('aboutustitle', title);
    formdata.append('subTitle1', subtitle1);
    formdata.append('description', Description);
    formdata.append('subTitle2', subtitle2);
    formdata.append('description1', Description1);
    formdata.append('description2', Description2);
    formdata.append('visionDescription', vissionDescription);
    formdata.append('missionDescription', missionDescription);

    //All input fields are Required
    if (!image || !title || !subtitle1 || !Description || !subtitle2 || !Description1
      || !Description2 || !vissionDescription || !missionDescription.trim()) {
      alert('All fields are required');
      return;
    }

    try {
      const config = {
        url: "/postaboutusdetails",
        method: "post",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)

      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getAboutus();
        setTitle('');
        setDescription('');
        setSubTitle1('');
        setDescription1('');
        setSubTitle2('');
        setDescription2('');
        setVissionDescription('');
        setMissionDescription('');
      }

    }
    catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }

  //Get Method
  const [data, setdata] = useState([]);
  //  console.log(data,"Service")
  const getAboutus = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/webmanagement/getaboutusdetails');
      if (res.status === 200) {
        setdata(res.data.status)
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
      alert('Failed to fetch banners');
    }
  };

  useEffect(() => {
    getAboutus()
  }, [])
  console.log(Description, 'Description');
  

  //Delete Method
  const [edit, setEdit] = useState({})
  console.log(edit, "sditeeee")

  const deletAboutus = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/webmanagement/deleteaboutusdetails/${edit?._id}`)
      if (res.status === 200) {
        alert("Deleted Successfully")
        handleClose2()
        getAboutus([])
      }
    } catch (error) {
      console.log(error)
    }
  }


  //Edit Banner
  // console.log("edit",edit);
  useEffect(() => {
    if (edit) {
      setTitle(edit?.aboutustitle || "")
      setDescription(edit?.description || "")
      setSubTitle1(edit?.subtitle1 || "")
      setDescription1(edit?.Description1 || "")
      setSubTitle2(edit?.subtitle2 || "")
      setDescription2(edit?.Description2 || "")
      setVissionDescription(edit?.vissionDescription || "")
      setMissionDescription(edit?.missionDescription || "")
    }
  }, [])

  const editAboutus = async () => {
    const formdata = new FormData()
    formdata.append('aboutusimg', image);
    formdata.append('aboutustitle', title);
    formdata.append('description', Description);
    formdata.append('subTitle1', subtitle1);
    formdata.append('description1', Description1);
    formdata.append('subTitle2', subtitle2);
    formdata.append('description2', Description2);
    formdata.append('visionDescription', vissionDescription);
    formdata.append('missionDescription', missionDescription);

    //All input fields are Required
    if (!image && !title && !subtitle1 && !Description && !subtitle2 && !Description1
      && !Description2 && !vissionDescription && !missionDescription.trim()) {
      alert('Please Update Any One field');
      return;
    }
    try {
      const config = {
        url: `/updateaboutusdetails/${edit?._id}`,
        method: "put",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        handleClose1();
        getAboutus();
        // after updated the inputfields will be clear
        setTitle('');
        setDescription('');
        setSubTitle1('');
        setDescription1('');
        setSubTitle2('');
        setDescription2('');
        setVissionDescription('');
        setMissionDescription('');
      }

    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }


  //<--------------------Our Services--------------------->

  //post

  const [ourteamimg, setOurteamimg] = useState('')
  const [ourteamName, setOurteamName] = useState('')
   const [designation, setDesignation] = useState('')

  const AddTeam = async () => {

    const formdata = new FormData()

    formdata.append('ourteamimg', ourteamimg);
    formdata.append('ourteamName', ourteamName);
    formdata.append('designation', designation);
    
    // formdata.append('servicesDescription', servicesDescription)
    if (!ourteamimg || !ourteamName ||!designation) {
      alert("All fields are required");
      return;
    }


    try {
      const config = {
        url: "/postourteamdetails",
        method: "post",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)

      if (res.status === 200) {
        alert(res.data.success);
        getTeam()
        handleClose();
        setOurteamName('');
        setDesignation('');
        setOurteamimg('')
      }

    }
    catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }

  //Get Method
  const [data1, setdata1] = useState([]);

  const getTeam = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/webmanagement/getourteamdetails');
      if (res.status === 200) {
        setdata1(res.data.success)
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
      alert('Failed to fetch banners');
    }
  };
  console.log(data1, "Service")
  useEffect(() => {
    getTeam()
  }, [])

  const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of items per page

    // Logic for displaying current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data1?.slice(indexOfFirstItem, indexOfLastItem);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(data1?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);


  //Delete Method
  const deletOurTeam = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/webmanagement/deleteourteamdetails/${edit?._id}`)
      if (res.status === 200) {
        alert("Deleted Successfully")
        handleClose2()
        getTeam([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  //Edit Banner
  // console.log(edit,"edit");
  useEffect(() => {
    if (edit) {
      setOurteamName(edit?.ourteamName || "")
      // setServicesDescription(edit.servicesDescription || "")
    }
  }, [])
  const editOurTeam = async () => {
    const formdata = new FormData()

    formdata.append('ourteamimg', ourteamimg);
    formdata.append('ourteamName', ourteamName);
   formdata.append('designation', designation);
    if (!ourteamimg && !ourteamName && !designation) {
      alert("Please update any one field");
      return;
    }

    try {
      const config = {
        url: `/updateourteamdetails/${edit?._id}`,
        method: "put",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        handleClose1();
        getTeam();
        // after updated the inputfields will be clear
        setOurteamName('');
        setDesignation('');
        setOurteamimg('');
      }

    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }

  // <----------------What Client Say?---------------->
  // Post Method


  const [clientName, setClientName] = useState('')
  const [clientDescription, setClientDescription] = useState('')
  // const [clientImage,setClientImage] = useState(null);


  const AddClientSay = async () => {

    // const formdata = new FormData()

    // formdata.append('clientImage', clientImage);
    // formdata.append('clientName',clientName);
    //  formdata.append('clientDescription',clientDescription)
    if (!clientName || !clientDescription) {
      alert("All fields required");
      return;
    }
    try {
      const config = {
        url: "/postwhatclientsaysdetails",
        method: "post",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        headers: { "content-type": "application/json" },
        data: {
          clientName: clientName,
          clientDescription: clientDescription,
        },
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        getClient();
        handleClose();
        setClientName('');
        setClientDescription('');
      }

    }
    catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }

  //Get Method
  const [data2, setdata2] = useState([]);

  const getClient = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/webmanagement/getwhatclientsaysdetails');
      if (res.status === 200) {
        setdata2(res.data.success); // Ensure the key matches the response
      }
    } catch (error) {
      console.error('Error fetching client details:', error);
      alert('Failed to fetch client details');
    }
  };


  useEffect(() => {
    getClient()
  }, [])
 // State for pagination
 const [currentPage1, setCurrentPage1] = useState(1);
    const itemsPerPage1 = 5; // Number of items per page


 // Logic for displaying current items
 const indexOfLastItem1 = currentPage1 * itemsPerPage1;
 const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
 const currentItems1 = data2?.slice(indexOfFirstItem1, indexOfLastItem1);

 // Logic for displaying page numbers
 const pageNumbers1 = [];
 for (let i = 1; i <= Math.ceil(data2?.length / itemsPerPage1); i++) {
     pageNumbers1.push(i);
 }

 // Handle page change
 const paginate1 = (pageNumber1) => setCurrentPage1(pageNumber1);

  //Delete Method
  const deletWhatClientSay = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/webmanagement/deletewhatclientsaysdetails/${edit?._id}`)
      if (res.status === 200) {
        alert("Deleted Successfully")
        handleClose2()
        getClient([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  //Edit Banner
  // console.log(edit,"edit");
  useEffect(() => {
    if (edit) {
      setClientName(edit?.clientName || "")
      setClientDescription(edit?.clientDescription || "")
    }
  }, [])

  const EditClientSay = async () => {
    // const formdata = new FormData()
    // formdata.append('clientImage', clientImage);
    // formdata.append('clientName',clientName);
    // formdata.append('clientDescription',clientDescription)

    if (!clientName && !clientDescription) {
      alert("Please Update any one field");
      return;
    }

    try {
      const config = {
        url: `updatewhatclientsaysdetails/${edit?._id}`,
        method: "put",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        headers: { "content-type": "multipart/form-data" },
        data: {
          clientName: clientName,
          clientDescription: clientDescription,
        },
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        handleClose1();
        getClient();
        // after updated the inputfields will be clear
        setClientName('');
        setClientDescription('');
      }
    }
    catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }




  // <-----------------Why Choose us??------------------>

  //Post Method
  const [chooseImage, setChooseImage] = useState('');
  const [chooseName, setChooseName] = useState('');
  const [chooseText, setChooseText] = useState('');


  const AddAchivements = async () => {
    const formdata = new FormData();
    formdata.append("chooseImage", chooseImage);
    formdata.append("chooseName", chooseName);
    formdata.append("chooseText", chooseText);
    if (!chooseImage || !chooseName || !chooseText) {
      alert("All Fields are required");
      return;
    }

    try {
      const config = {
        url: "/postachievementdetails",
        method: "post",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAchivement();
        handleClose();
        setChooseName('');
        setChooseText('');
      }
    } catch (error) {
      console.error('Error adding achievement:', error);
      alert('Failed to add achievement');
    }
  };


  //Get Method
  const [data3, setdata3] = useState([]);
  const [currentPage2, setCurrentPage2] = useState(1);
  const itemsPerPage2 = 5; // Items per page for pagination

    // Pagination logic
    const indexOfLastItem2 = currentPage2 * itemsPerPage2;
    const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage;
    const currentItems2 = data3.slice(indexOfFirstItem2, indexOfLastItem2);
    const totalPages2 = Math.ceil(data3.length / itemsPerPage2);
  
    const handlePageChange = (pageNumber) => setCurrentPage2(pageNumber);

  const getAchivement = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/webmanagement/getachievementdetails');
      if (res.status === 200) {
        setdata3(res.data.success)
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
      alert('Failed to fetch banners');
    }
  };
  console.log(data1, "Service")

  useEffect(() => {
    getAchivement()
  }, [])


  

  console.log(data3, "fireyiueri");

  //Delete Method
  const deletAchievements = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/webmanagement/deleteachievementdetails/${edit?._id}`)
      if (res.status === 200) {
        alert("Deleted Successfully")
        handleClose2()
        getAchivement([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  //update Method

  // console.log(edit,"edit");
  useEffect(() => {
    if (edit) {
      setChooseName(edit?.chooseName || "")
      setChooseText(edit?.chooseText || "")
    }
  }, [])
  const EditAchivements = async () => {
    const formdata = new FormData();
    formdata.append("chooseImage", chooseImage);
    formdata.append("chooseName", chooseName);
    formdata.append("chooseText", chooseText);

    if (!chooseImage && !chooseName && !chooseText) {
      alert("Please Update any one field");
      return;
    }


    try {
      const config = {
        url: `updateachievementdetails/${edit?._id}`,
        method: "put",
        baseURL: "https://talharconstructions.in/api/webmanagement",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAchivement();
        handleClose1();
        setChooseName('');
        setChooseText('');
      }
    } catch (error) {
      console.error('Error updating achievement:', error);
      alert('Failed to update achievement');
    }
  };

  return (
    <div>
      <Row>
        <div className='p-3 d-flex gap-3'>
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }} onClick={() => {
            setAbout(true);
            setSevice(false);
            setChoose(false);
            setNumbers(false);
          }}>About Us</Button>
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }}
            onClick={() => {
              setAbout(false);
              setSevice(true);
              setChoose(false);
              setNumbers(false);
            }}
          >Our Team</Button>
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }} onClick={() => {
            setAbout(false);
            setSevice(false);
            setChoose(true);
            setNumbers(false);
          }}>What Client Says?</Button>
          <Button variant="" style={{ background: "rgb(3, 146, 182)", color: "white" }} onClick={() => {
            setAbout(false);
            setSevice(false);
            setChoose(false);
            setNumbers(true);
          }}>Why Choose Us??</Button>
        </div>
      </Row>

      {about ? (<>
        <div>
          <h1 className='h1-style'>About Us</h1>
          {data.length ? (
            ""
          ) : (<Button className='add-buttons'
            onClick={handleShow}>
            +Add
          </Button>)}

        </div>
        <div>

          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>About Us </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <div class="mt-3">
                      <label htmlFor="inputGroupFile03">Image</label>
                      <div>
                        <input type="file"
                          class="form-control"
                          id="inputGroupFile03"
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          accept="image/*"
                          onChange={(e) => setImage(e.target.files[0])} />

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div class="mt-3">
                      <div>
                        <input type="text"
                          placeholder='Title..'
                          style={{ width: "100%" }}
                          id="input-border"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div class="mt-3">
                      <div>
                        <CKEditor
                          editor={ClassicEditor}
                          data={description}
                          onChange={handleDescriptionChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div class="mt-3">

                        <div>
                          <input type="text"
                            placeholder='subtitle1...'
                            style={{ width: "100%" }}
                            id="input-border"
                            value={subtitle1}
                            onChange={(e) => setSubTitle1(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div class="mt-3">
                        <div>
                          <CKEditor
                            editor={ClassicEditor}
                            data={Description1}
                            onChange={handleDescriptionChange1}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div class="mt-3">
                          <div>
                            <input type="text"
                              placeholder='subtitle2...'
                              style={{ width: "100%" }}
                              id="input-border"
                              value={subtitle2}
                              onChange={(e) => setSubTitle2(e.target.value)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div class="mt-3">
                          <div>
                            <CKEditor
                              editor={ClassicEditor}
                              data={Description2}
                              onChange={handleDescriptionChange2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-8">
                          <div class="mt-3">

                            <div>
                              <input type="text"
                                placeholder='Mission Description.....'
                                style={{ width: "100%" }}
                                id="input-border"
                                value={missionDescription}
                                onChange={(e) => setMissionDescription(e.target.value)} />

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <div>

                              <div class="mt-3">
                                <input type="text"
                                  placeholder='Vision Description....'
                                  style={{ width: "100%" }}
                                  id="input-border"
                                  value={vissionDescription}
                                  onChange={(e) => setVissionDescription(e.target.value)} />

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>

              <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddAbout}>
                Add
              </Button>



            </Modal.Footer>
          </Modal>

          <Table striped bordered hover size="sm">
            <thead>
              <tr>

                <th>Image</th>
                <th>Title</th>
                <th>Description</th>
                <th>SubTitle1</th>
                <th>Description1</th>
                <th>SubTitle2</th>
                <th>Description2</th>
                <th>Vision Description</th>
                <th>Mission Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr>
                    <td><div>
                      <img
                        src={`https://talharconstructions.in/Aboutusimage/${item?.aboutusimg}`}
                        alt="AboutUsimage"
                        style={{ width: '100px', height: '100px' }}
                      />
                    </div></td>
                    <td>{(item.aboutustitle)}</td>

                    <td>
                      <div style={{ width: "auto", overflow: "hidden", overflowY: "scroll", height: "100px" }}>
                        {stripHtmlTags(item.description)}
                      </div>
                    </td>

                    <td>{item.subTitle1}</td>
                    <td>
                      <div style={{ width: "auto", overflow: "hidden", overflowY: "scroll", height: "100px" }}>
                        {stripHtmlTags(item.description1)}
                      </div>
                    </td>

                    <td>{item.subTitle2}</td>
                    <td>
                      <div style={{ width: "auto", overflow: "hidden", overflowY: "scroll", height: "100px" }}>
                      {stripHtmlTags(item.description2)}
                      </div>
                      </td>
                    <td>
                      <div style={{ width: "auto", overflow: "hidden", overflowY: "scroll", height: "100px" }}>
                      {stripHtmlTags(item.visionDescription)}
                      </div>
                     </td>
                    <td>
                      <div style={{ width: "auto", overflow: "hidden", overflowY: "scroll", height: "100px" }}>
                      {stripHtmlTags(item.missionDescription)}
                        </div></td>
                    <td>

                      <div className="d-flex">
                        <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                          onClick={() => {
                            handleShow1();
                            setEdit(item)
                          }} /> /
                        <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                          onClick={() => {
                            handleShow2();
                            setEdit(item)
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </Table>

          {/* Delete banner modal */}
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#080874" }}>
                <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className='paragraphs'>Are you Sure?</p>
              <p className='paragraphs'>You want to delete this data?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
              <Button style={{ backgroundColor: '#080874' }} onClick={deletAboutus}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>

          {/* edit banner modal */}
          <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
                Edit About Us
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div class="mt-3">
                <label htmlFor="inputGroupFile03">Image</label>
                <div>
                  <input type="file"
                    class="form-control"
                    id="inputGroupFile03"
                    aria-describedby="inputGroupFileAddon03"
                    aria-label="Upload"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])} />

                </div>
              </div>
              <div class="mt-3">
                {/* <label  >Tittle</label> */}
                <div>
                  <input type="text"
                    placeholder='Title..'
                    style={{ width: "100%" }}
                    id="input-border"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)} />
                </div>
              </div>
              <div class="mt-3">
                {/* <label  >Description</label> */}
                <div>
                  {/* <input type="text"
                    placeholder='Describtion..'
                    style={{ width: "100%" }}
                      id="input-border"
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={Description}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
              <div class="mt-3">
                {/* <label  >SubTitle1</label> */}
                <div>
                  <input type="text"
                    placeholder='subtitle1...'
                    style={{ width: "100%" }}
                    id="input-border"
                    value={subtitle1}
                    onChange={(e) => setSubTitle1(e.target.value)} />


                </div>
              </div>
              <div class="mt-3">
                {/* <label  >Description</label> */}
                <div>
                  {/* <input type="text"
                    placeholder='Describtion..'
                    style={{ width: "100%" }}
                      id="input-border"
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={handleDescriptionChange1}
                  />
                </div>
              </div>
              <div class="mt-3">
                {/* <label  >SubTitle2</label> */}
                <div>
                  <input type="text"
                    placeholder='subtitle2...'
                    style={{ width: "100%" }}
                    id="input-border"
                    value={subtitle2}
                    onChange={(e) => setSubTitle2(e.target.value)} />
                </div>
              </div>
              <div class="mt-3">
                {/* <label  >Description</label> */}
                <div>
                  {/* <input type="text"
                    placeholder='Describtion..'
                    style={{ width: "100%" }}
                      id="input-border"
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={handleDescriptionChange2}
                  />
                </div>
              </div>
              <div class="mt-3">
                {/* <label htmlFor="">Vission Description</label> */}
                <div>
                  <input type="text"
                    placeholder='Mission Description.....'
                    style={{ width: "100%" }}
                    id="input-border"
                    value={missionDescription}
                    onChange={(e) => setMissionDescription(e.target.value)} />

                </div>
              </div>
              <div>
                {/* <label htmlFor="">Mission Description</label> */}
                <div class="mt-3">
                  <input type="text"
                    placeholder='Vision Description....'
                    style={{ width: "100%" }}
                    id="input-border"
                    value={vissionDescription}

                    onChange={(e) => setVissionDescription(e.target.value)} />

                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editAboutus}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>

        </div>

      </>) : (<></>)}

      {Sevice ? (<>
        <h1 className='h1-style' >Our Team</h1>
        <Button className='add-buttons' onClick={handleShow}>
          +Add
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Our Team
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              <label htmlFor="inputGroupFile03">Image</label>
              <div>
                <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload"
                  accept="image/*"
                  onChange={(e) => setOurteamimg(e.target.files[0])} />
              </div>
            </div>
            <div class="mt-3">
              {/* <label htmlFor=""> Name</label> */}
              <div>
                <input type="text"
                  placeholder='name.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={ourteamName}
                  onChange={(e) => setOurteamName(e.target.value)} />
              </div>
            </div>

            <div class="mt-3">
              {/* <label htmlFor=""> designation</label> */}
              <div>
                <input type="text"
                  placeholder='designation.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={designation}
                        onChange={(e) => setDesignation(e.target.value)} />
              </div>
            </div>
            {/* <div class="mt-3">
              <div>
                <CKEditor
                  editor={ClassicEditor}
                  data={servicesDescription}
                  onChange={handleDescriptionChange3}
                />
              </div>
            </div> */}


          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddTeam}>
              Add
            </Button>



          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#080874" }}>
              <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: '#080874' }} onClick={deletOurTeam}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Our Team
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className='mb-2'>
              <input type="file"
                class="form-control"
                id="inputGroupFile03"
                aria-describedby="inputGroupFileAddon03"
                aria-label="Upload"
                accept="image/*"
                onChange={(e) => setOurteamimg(e.target.files[0])} />

            </div>

            <div class="mt-3">
              {/* <label htmlFor=""> Name</label> */}
              <div>
                <input type="text"
                  placeholder='name.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={ourteamName}
                  onChange={(e) => setOurteamName(e.target.value)} />
              </div>
            </div>

            
            <div class="mt-3">
              {/* <label htmlFor=""> designation</label> */}
              <div>
                <input type="text"
                  placeholder='designation.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={designation}
                        onChange={(e) => setDesignation(e.target.value)} />
              </div>
            </div>
            {/* <div class="mt-3">
            
              <div>
               
                <CKEditor
                  editor={ClassicEditor}
                  data={servicesDescription}
                  onChange={handleDescriptionChange3}
                />
              </div>
            </div> */}



          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editOurTeam}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
        <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.map((item, index) => (
                        <tr key={item._id}>
                            <td>{item.ourteamName}</td>
                            <td>{item.designation}</td>
                            <td>
                                <div>
                                    <img
                                        src={`https://talharconstructions.in/Ourteamimage/${item?.ourteamimg}`}
                                        alt="OurTeamimage"
                                        style={{ width: '100px', height: '100px' }}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="d-flex gap-3 justify-content-center">
                                    <FaRegEdit
                                        style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow1();
                                            setEdit(item);
                                        }}
                                    /> /
                                    <MdDelete
                                        style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow2();
                                            setEdit(item);
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <nav>
                <ul className='pagination'>
                    {pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate(number)} className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
      </>) : (<></>)}

      {Choose ? (<>
        <h1 className='h1-style'>What Client Say?</h1>
        <Button className='add-buttons' onClick={handleShow}>
          +Add
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              What Client Say?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="mt-3">
              {/* <label htmlFor=""> Name</label> */}
              <div>
                <input type="text"
                  placeholder=' Name.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)} />
              </div>
            </div>
            {/* <div className="mt-3">
              <div>
              <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload"
                  accept="image/*"
                  onChange={(e) => setClientImage(e.target.files[0])} />
              </div>
            </div> */}
            <div class="mt-3" >
              {/* <label htmlFor=""> Description</label> */}
              <div>
                {/* <input type="text"
                  placeholder='Description'
                  style={{ width: "100%" }}
                   id="input-border"
                /> */}
                <CKEditor
                
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddClientSay}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deletWhatClientSay}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit What Client Say
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div class="mt-3">
              {/* <label htmlFor=""> Name</label> */}
              <div>
                <input type="text"
                  placeholder=' Name.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)} />
              </div>
            </div>
            {/* <div className="mt-3">
              <div>
              <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload"
                  accept="image/*"
                  onChange={(e) => setClientImage(e.target.files[0])} />
              </div>
            </div> */}
            <div class="mt-3">
              {/* <label htmlFor=""> Description</label> */}
              <div>
                {/* <input type="text"
                  placeholder='Description'
                  style={{ width: "100%" }}
                   id="input-border"
                /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={EditClientSay}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
        <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th style={{ width: "70%" }}>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems1?.map((item, index) => (
                        <tr key={item._id}>
                            <td>{item.clientName}</td>
                            <td>
                                <div style={{ width: "auto", height: "100px", overflow: "hidden", overflowY: "scroll" }}>
                                    {stripHtmlTags(item.clientDescription)}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex gap-2 justify-content-center">
                                    <FaRegEdit
                                        style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow1();
                                            setEdit(item);
                                        }}
                                    /> /
                                    <MdDelete
                                        style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow2();
                                            setEdit(item);
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <nav>
                <ul className='pagination'>
                    {pageNumbers1.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate1(number)} className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>

      </>) : (<></>)}


      {Numbers ? (<>
        <h1 className='h1-style'>why Choose Us??</h1>

        <div>

          <Button className='add-buttons'
            onClick={handleShow}>
            +Add
          </Button>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Why Choose US
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              <label htmlFor="">Title</label>
              <div>
                <input type="text"
                  placeholder='Title....'
                  style={{ width: "100%" }}
                  value={chooseName}
                  onChange={(e) => setChooseName(e.target.value)} />
              </div>
            </div>
            <div class="mt-3">
              <label htmlFor="inputGroupFile03">Image</label>
              <div>
                <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload"

                  accept="image/*"
                  onChange={(e) => setChooseImage(e.target.files[0])} />

              </div>
            </div>

            <div>
              <label htmlFor="">Text</label>
              <div>
                {/* <input type="text"
                  placeholder='Banner Text....'
                  style={{ width: "100%" }}
                  value={chooseText}
                  onChange={(e) => setChooseText(e.target.value)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={chooseText}
                  onChange={handleDescriptionChange4}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddAchivements}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>



        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deletAchievements}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Why Choose Us
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div>
              <label htmlFor="">Title</label>
              <div>
                <input type="text"
                  placeholder='Title....'
                  style={{ width: "100%" }}
                  value={chooseName}
                  onChange={(e) => setChooseName(e.target.value)} />
              </div>
            </div>
            <div class="mt-3">
              <label htmlFor="inputGroupFile03">Image</label>
              <div>
                <input type="file"
                  class="form-control"
                  id="inputGroupFile03"
                  aria-describedby="inputGroupFileAddon03"
                  aria-label="Upload"
                  accept="image/*"

                  onChange={(e) => setChooseImage(e.target.files[0])} />

              </div>
            </div>
            <div>
              <label htmlFor="">Text</label>
              <div>
                {/* <input type="text"
                  placeholder='Banner Text....'
                  style={{ width: "100%" }}
                  value={chooseText}
                  onChange={(e) => setChooseText(e.target.value)} /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={chooseText}
                  onChange={handleDescriptionChange4}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={EditAchivements}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <Table striped bordered hover size="sm"  >
          <thead className=' bg-navy'>
            <tr >
              <th>Title</th>
              <th>Image</th>
              <th style={{width:"30%"}}>Description</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {currentItems2?.map((item, index) => {
              return (
                <tr >
                  <td>{item.chooseName}</td>
                  <td>
                    <div>
                      <img src={`https://talharconstructions.in/Aboutusimage/${item?.chooseImage}`} alt="chooseImage"
                        style={{ width: '120px', height: '100px' }} />
                    </div>
                  </td>
                  <td>
                    <div style={{ width: "auto", overflow: "hidden", overflowY: "scroll", height: "100px" }}>
                      {stripHtmlTags(item.chooseText)}
                    </div>
                  </td>
                
                  <td>{moment(item?.createdAt).format("DD-MM-YY")}</td>
                  <td>
                    <div className="d-flex gap-2">
                      <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                        onClick={() => {
                          handleShow1();
                          setEdit(item)
                        }} /> /
                      <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                        onClick={() => {
                          handleShow2();
                          setEdit(item)
                        }}
                      />
                    </div>

                  </td>
                </tr>
              )
            })}

          </tbody>
        </Table>

          {/* Pagination Component */}
      <Pagination className='justify-content-center'>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage2 - 1)} disabled={currentPage2 === 1} />
        {[...Array(totalPages2).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber + 1}
            active={pageNumber + 1 === currentPage2}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage2 + 1)} disabled={currentPage2 === totalPages2} />
        <Pagination.Last onClick={() => handlePageChange(totalPages2)} disabled={currentPage2 === totalPages2} />
      </Pagination>
      </>) : (<></>)}

    </div>
  )
}

export default WebManagement