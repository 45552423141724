import React, { useState } from 'react'
import axios from 'axios'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const AdminLogin = () => {
  const [AEmail, setAEmail] = useState('')
  const [Apassword, setApassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const AddAdminLogin = async (e) => {
    e.preventDefault();

 // formdata.append('servicesDescription', servicesDescription)
 if (!AEmail && !Apassword ) {
  alert("Please Enter Admin Email And Password");
  return;
}
else if (!AEmail) {
  alert("Please Enter Admin Email");
}
else if (!Apassword) {
  alert("Please Enter Admin Password");
}


    try {
      const config = {
        url: "/AdminLogin",
        method: "post",
        baseURL: "https://talharconstructions.in/api/admin",
        Headers: { "content-type": "application/json" },
        data: {
          AEmail: AEmail,
          Apassword: Apassword,
        }
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        console.log(res.data.success)
        window.location.assign('/adminPanel')  // getting to direclty adminPanel
       
      }
    }
    catch (error) {
      console.error('Error logging in:', error);
      // alert('Please Enter Valid Email Id');
      // if(!AEmail){
      //   alert("Please Enter Email Id");
      //   return;
      // }
      // if(!Apassword){
      //   alert("Please Enter Password");
      //   return;
      // }
      if (error.response) {
        if (error.response.status === 401) {
          // Assuming 401 status code for incorrect credentials
          alert('Incorrect email or password.  Please try again.');
        } 
      }
    }
  }



  // console.log(AEmail,Apassword,"ahfhfdyhu");

  return (
    <div>
      <section className="vh-100 p-lg-5 " style={{ backgroundColor: "rgb(3, 146, 182)" }}>
        <div
          class="container p-lg-5"
          style={{
            maxWidth: "900px",
          }}
        >
          <div className="row  d-flex justify-content-center align-items-center">
            <div className="col col-xl-10">
              <div className="card" style={{ borderRadius: "1rem" }}>
                <div className="row g-0">

                  <div className="col-md-6 col-lg-5 d-none d-md-block align-items-center mt-5">
                    <img
                      src="../Assets/talharhd-logo1.jpeg"
                      height={150}
                      alt="login form"
                      className=""
                      style={{ borderRadius: "1rem 0 0 1rem", width: "120%", marginTop: '80px' }} />
                  </div>
                  <div className="col-md-6 col-lg-7 d-flex align-items-center">
                    <div className=" p-lg-5 text-black">

                      <form onSubmit={AddAdminLogin}>
                        <h4 className=" fs-lg-4 p-lg-3 adminlogin">
                          {" "}
                          Admin Login
                        </h4>
                        <div className="form-outline mb-3">
                          <label className="form-label" >
                            Email address
                          </label>

                          <input
                            type="email"
                            id="form2Example17"
                            className="form-control form-control-lg inputdesign"
                            placeholder="admin@gmail.com"
                            value={AEmail}
                            onChange={(e) => setAEmail(e.target.value)}
                          />
                        </div>

                        <div className="form-outline mb-3 " >
                          {/* <label className="form-label" for="form2Example27">
                            Password
                          </label> */}

                          <div className='d-flex gap-2'>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              name='password'
                              id="form2Example27"
                              className="form-control form-control-lg inputdesign"
                              placeholder="Enter Password"
                              value={Apassword}
                              onChange={(e) => setApassword(e.target.value)} />
                             <button
                            
                              type="button"
                              className="btn  mb-1 btn-lg"
                              onClick={handleTogglePassword}>

                              {showPassword ? <FaEye /> :<FaEyeSlash /> }
                            </button>

                          </div>
                        </div>
                           <div><a href="">Forgot Password??</a></div>
                        <div class="pt-1 mb-3 adminlbtn">
                          <button
                            className="btn btn-dark btn-lg btn-block"
                            type="submit">Login</button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AdminLogin