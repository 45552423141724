import React, { useState, useEffect } from "react";
import { Button, Row, Table, Modal } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import './Admin.css'
import axios from 'axios'
import moment from 'moment';

const Enquiries = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  //Post tMethod
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');



  const AddEnquiries = async () => {


    const formdata = new FormData();

    formdata.append('name', name);
    formdata.append('phoneNumber', phoneNumber);
    formdata.append('email', email);
    formdata.append('message', message);
    try {
      const config = {
        url: '/postenquiries',
        method: 'post',
        baseURL: 'https://talharconstructions.in/api/enquiries',
        headers: { 'content-type': 'application/json' },
        data: formdata,
      };
      const res = await axios(config);

      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getEnquiryData();
      }

    }
    catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }

  //Get Method
  const [data, setdata] = useState([]);
  console.log(data, "enquiries")
  const getEnquiryData = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/enquiries/getenquiries');
      if (res.status === 200) {
        setdata(res.data.success);
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
      alert('Failed to fetch banners');
    }
  };

  useEffect(() => {
    getEnquiryData()
  }, [])

  //Delete Method
  const [edit, setEdit] = useState({})
  console.log(edit, "sditeeee")

  const deletData = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/enquiries/deleteenquiries/${edit?._id}`)
      if (res.status === 200) {
        alert("Delete Successfully")
        handleClose2()
        getEnquiryData();
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div>
      <h1 className='h1-style'>Enquiries </h1>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#080874" }}>Enquiries</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="mt-3">
            <label>Name</label>
            <div>
              <input
                type='text'
                placeholder='name..'
                style={{ width: '100%' }}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div>
            <label>Phone Number</label>
            <div>
              <input
                type='text'
                placeholder='PhoneNumber......'
                style={{ width: '100%' }}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />

            </div>
          </div>
          <br />

          <div>
            <label>Email</label>
            <div>
              <input
                type='text'
                placeholder='email....'
                style={{ width: '100%' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <br />

          <div>
            <div>
              <input
                type='text'
                placeholder='message....'
                style={{ width: '100%' }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleShow}>
            Close
          </Button>

          <Button style={{ backgroundColor: '#080874' }} onClick={AddEnquiries}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete banner modal */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#080874" }}>
            <h3 style={{ color: "green" }}>WARNING !!</h3>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='paragraphs'>Are you Sure?</p>
          <p className='paragraphs'>You want to delete this data?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button style={{ backgroundColor: '#080874' }} onClick={deletData}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>PhoneNumber</th>
              <th>Email</th>
              <th>Message</th>
              <th>Date</th> 
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>
                  <td>{item.message}</td>
                  <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td> {/* Display the date */}
                  <td>
                    {/* <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }} onClick={handleShow1} /> / */}
                    <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                      onClick={() => {
                        handleShow2();
                        setEdit(item)
                      }}
                    />
                  </td>
                </tr>
              )
            })}

          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Enquiries