import React, { useEffect, useState } from 'react';
import { Row, Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination'; // Import Pagination component
import './Admin.css';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { IoWarningOutline } from 'react-icons/io5';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';

const Partners = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [partnersimg, setPartnersimg] = useState('');
  const [partnersDescription, setPartnersDescription] = useState('');
  const [partnerName, setPartnerName] = useState('');
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Items per page for pagination

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await axios.get('https://talharconstructions.in/api/partners/getpartner');
      if (res.status === 200) {
        setData(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setPartnersDescription(data);
  };

  const stripHtmlTags = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  };

  const AddBanner = async () => {
    const formdata = new FormData();
    formdata.append('partnerName', partnerName);
    formdata.append('partnersimg', partnersimg);
    formdata.append('partnersDescription', partnersDescription);

    if (!partnerName || !partnersimg || !partnersDescription.trim()) {
      alert('All fields are required');
      return;
    }
    try {
      const config = {
        url: '/postpartner',
        method: 'post',
        baseURL: 'https://talharconstructions.in/api/partners',
        Headers: { 'content-type': 'multipart/form-data' },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getData();
        setPartnersDescription('');
        setPartnerName('');
      }
    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  };

  const [edit, setEdit] = useState({});

  useEffect(() => {
    if (edit) {
      setPartnersDescription(edit?.partnersDescription || '');
      setPartnerName(edit?.partnerName || '');
    }
  }, [edit]);

  const editBanner = async () => {
    const formdata = new FormData();
    formdata.append('partnerName', partnerName);
    formdata.append('partnersimg', partnersimg);
    formdata.append('partnersDescription', partnersDescription);

    if (!partnerName && !partnersimg && !partnersDescription.trim()) {
      alert('Please update any one field');
      return;
    }
    try {
      const config = {
        url: `/updatepartner/${edit?._id}`,
        method: 'put',
        baseURL: 'https://talharconstructions.in/api/partners',
        Headers: { 'content-type': 'multipart/form-data' },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        handleClose1();
        getData();
        setPartnersDescription('');
        setPartnerName('');
      }
    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  };

  const deletData = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/partners/deletepartner/${edit?._id}`);
      if (res.status === 200) {
        alert('Deleted Successfully');
        handleClose2();
        getData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div>
        <h1 className='h1-style'>Latest Projects</h1>
        <Button className='add-buttons' onClick={handleShow}>
          +Add
        </Button>
      </div>
      {/* Add Modal, Edit Modal, Delete Modal code here */}

      <Modal show={show} onHide={handleClose}>
<Modal.Header closeButton>
  <Modal.Title style={{ color: "#080874" }}>
   Add Projects
  </Modal.Title>
</Modal.Header>
<Modal.Body>

  <div className='mb-2'>
    <label htmlFor="inputGroupFile03">Image</label>
    <input type="file"
      class="form-control"
      id="inputGroupFile03"
      aria-describedby="inputGroupFileAddon03"
      aria-label="Upload"
      acept="image/*"
      onChange={(e) => setPartnersimg(e.target.files[0])} />
  </div>
  <div className='mb-2'>
    <label htmlFor="">Name</label>
  <input type="text"
          placeholder=' Name.....'
          style={{ width: "100%" }}
          id="input-border"
          value={partnerName}
          onChange={(e) => setPartnerName(e.target.value)} />
  </div>

  {/* <label  >Description</label> */}
  <div>
    {/* <input type="text"
            placeholder='Describtion..'
            style={{ width: "100%" }}
              id="input-border"
          /> */}
    <CKEditor
      editor={ClassicEditor}
      data={partnersDescription}
      onChange={handleDescriptionChange}
    />
  </div>
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose}>
    Close
  </Button>
  <Button style={{ backgroundColor: '#080874' }} onClick={AddBanner}>
    Add
  </Button>
</Modal.Footer>
</Modal>

      <Table striped bordered hover size='sm'>
        <thead className='text-center'>
          <tr>
            <th>Sl.No</th>
            <th>Name</th>
            <th>Images</th>
            <th style={{ width: '50%' }}>Description</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item, index) => (
            <tr key={item._id}>
              <td>{indexOfFirstItem + index + 1}.</td>
              <td>{item.partnerName}</td>
              <td>
                <div>
                  <img
                    src={`https://talharconstructions.in/Partnersimage/${item?.partnersimg}`}
                    alt='Partnersimg'
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              </td>
              <td>
                <div style={{ width: 'auto', height: '100px', overflow: 'hidden', overflowY: 'scroll' }}>
                  {stripHtmlTags(item.partnersDescription)}
                </div>
              </td>
              <td>{moment(item?.createdAt).format('DD-MM-YY')}</td>
              <td>
                <div className='d-flex gap-2 justify-content-center'>
                  <FaRegEdit
                    style={{ cursor: 'pointer', color: 'green', fontSize: '30px' }}
                    onClick={() => {
                      handleShow1();
                      setEdit(item);
                    }}
                  />
                  /
                  <MdDelete
                    style={{ cursor: 'pointer', color: 'navy', fontSize: '30px' }}
                    onClick={() => {
                      handleShow2();
                      setEdit(item);
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>



      {/* Pagination Component */}
      <Pagination className='justify-content-center'>
        <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <Pagination.Item
            key={pageNumber + 1}
            active={pageNumber + 1 === currentPage}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>



            {/* Delete banner modal */}
<Modal show={show2} onHide={handleClose2}>
<Modal.Header closeButton>
  <Modal.Title style={{ color: "#080874" }}>
    <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
  </Modal.Title>
</Modal.Header>

<Modal.Body>
  <p className='paragraphs'>Are you Sure?</p>
  <p className='paragraphs'>You want to delete this data?</p>
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose2}>
    Close
  </Button>
  <Button style={{ backgroundColor: '#080874' }} onClick={deletData}>
    Delete
  </Button>
</Modal.Footer>
</Modal>

{/* edit banner modal */}
<Modal show={show1} onHide={handleClose1}>
<Modal.Header closeButton>
  <Modal.Title style={{ color: "#080874" }}>
    Edit Banner
  </Modal.Title>
</Modal.Header>
<Modal.Body>
  <div className='mb-2'>
    <label htmlFor="inputGroupFile04">Image</label>
    <input type="file"
      class="form-control"
      id="inputGroupFile04"
      aria-describedby="inputGroupFileAddon03"
      onChange={(e) => setPartnersimg(e.target.files[0])}
      aria-label="Upload" />
  </div>
  <div className='mb-2'>
  <input type="text"
          placeholder='Name.....'
          style={{ width: "100%" }}
          id="input-border"
          value={partnerName}
          onChange={(e) => setPartnerName(e.target.value)} />
  </div>

  {/* <label  >Description</label> */}
  <div>
    {/* <input type="text"
            placeholder='Describtion..'
            style={{ width: "100%" }}
              id="input-border"
          /> */}
    <CKEditor
      editor={ClassicEditor}
      data={partnersDescription}
      onChange={handleDescriptionChange}
    />
  </div>

</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={handleClose1}>
    Close
  </Button>
  <Button style={{ backgroundColor: '#080874' }} onClick={editBanner}>
    update
  </Button>
</Modal.Footer>
</Modal>

    </div>
  );
};

export default Partners;












