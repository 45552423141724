import React, { useState, useEffect } from 'react'
import { Row, Button, Modal, Table } from 'react-bootstrap';
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import axios from 'axios'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

function ConstructionPackages() {
    //Conditions
    const [Categories, setCategories] = useState(true);
    const [SubCategories, setSubCategories] = useState('');
    const [AddDescription, setAddDescription] = useState('');

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [show1, setShow1] = useState(false);
    const handleShow1 = () => setShow1(true);
    const handleClose1 = () => setShow1(false);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);



    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of items per page

   
    //<-------------Category--------------->
    const [categoryName, setCategoryName] = useState('');

    const AddCategoy = async () => {
        if(!categoryName){
            alert("All  Fields Are Required");
            return;
        }
        try {
            const config = {
                url: "/postCategoryDetails",
                method: "post",
                baseURL: "https://talharconstructions.in/api/admin",
                headers: { "content-type": 'application/json' },
                data: {
                    categoryName: categoryName,
                },
            };

            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                handleClose();
                getCategoryDetails();
                setCategoryName("")
            }
        } catch (error) {
            alert('Failed to fetch Category Details');
        }
    }
    //  Get Method
    const [data1, seData1] = useState([]);
    const getCategoryDetails = async () => {
        try {
            const res = await axios.get("https://talharconstructions.in/api/admin/getCategoryDetails");
            if (res.status === 200) {
                seData1(res.data.success)
            }
        } catch (error) {
            alert('Failed to fetch Ctegory Details');
        }
    }
    useEffect(() => {
        getCategoryDetails();
    }, [])


       // Logic for displaying current items
       const indexOfLastItem1 = currentPage * itemsPerPage;
       const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage;
       const currentItems1 = data1?.slice(indexOfFirstItem1, indexOfLastItem1);
   
       // Logic for displaying page numbers
       const pageNumbers1 = [];
       for (let i = 1; i <= Math.ceil(data1?.length / itemsPerPage); i++) {
           pageNumbers1.push(i);
       }
   
       // Handle page change
       const paginate1 = (pageNumber1) => setCurrentPage(pageNumber1);
    //Update Method
    const UpdateCategoryDetails = async () => {
        if(!categoryName){
            alert("Please update Any One Field");
            return;
        }
        try {
            const config = {
                url: `/updatecategoryDetails/${edit?._id}`,
                method: "put",
                baseURL: "https://talharconstructions.in/api/admin",
                headers: { "content-type": 'application/json' },
                data: {
                    categoryName: categoryName,
                },
            };

            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                handleClose1();
                getCategoryDetails();
            }
        } catch (error) {
            alert('Failed to fetch Category Details');
        }
    }

    //Delete Method

    const deleteCategorydetails = async () => {
        try {
            let res = await axios.delete(`https://talharconstructions.in/api/admin/deleteCategoryDetails/${edit?._id}`);
            if (res.status === 200) {
                alert(res.data.message);
                handleClose2();
                getCategoryDetails();
            }
        } catch (error) {
            console.log(error);

        }
    }
    // <-----------------SubCategory------------->
    const [Description, setDescription] = useState('');
    const [delight, setDelight] = useState('')
    const [delightPrice, setDelightPrice] = useState('')
    const [grand, setGrand] = useState('')
    const [grandPrice, setGrandPrice] = useState('')
    const [iconic, setIconic] = useState('')
    const [iconicPrice, setIconicPrice] = useState('')

    const AddSubCategory = async () => {
        if (!Description || !delight || !delightPrice || !grand || !grandPrice || !iconic || !iconicPrice.trim()) {
            alert('All fields are require');
            return;
        }
        try {
            const config = {
                url: "/postdesigndetails",
                method: "post",
                baseURL: "https://talharconstructions.in/api/admin",
                headers: { "content-type": 'application/json' },
                data: {
                    Description: Description,
                    delight: delight,
                    delightPrice: delightPrice,
                    grand: grand,
                    grandPrice: grandPrice,
                    iconic: iconic,
                    iconicPrice: iconicPrice,
                },
            };

            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                handleClose();
                getSubCategory();
                //Clear the input fields 
                setDescription("")
                setDelight("");
                setDelightPrice('');
                setGrand('');
                setGrandPrice('');
                setIconic('');
                setIconicPrice('');

            }
        } catch (error) {
            console.error('Error fetching Designs:', error);
            alert('Failed to fetch Designs');
        }
    }

    //Get SubCategory
    const [data, setdata] = useState([]);
    const getSubCategory = async () => {
        try {
            const res = await axios.get("https://talharconstructions.in/api/admin/getDesignDetails");
            if (res.status === 200) {
                setdata(res.data.success)
            }
        } catch (error) {
            alert('Failed to fetch banners');
        }
    }
    useEffect(() => {
        getSubCategory();
    }, [])
    

 
 

    //Delete Method
    const [edit, setEdit] = useState({});
    const deleteSubCategory = async (_id) => {
        try {
            let res = await axios.delete(`https://talharconstructions.in/api/admin/deleteDesignDetails/${edit?._id}`);
            if (res.status === 200) {
                alert("Deleted Successfully")
                handleClose2();
                getSubCategory([]);
            }
        } catch (error) {
            console.log(error);

        }
    }

    //Update Method
    const UpdateSubCategoryDetails = async () => {
        if (!Description && !delight && !delightPrice && !grand && !grandPrice && !iconic && !iconicPrice.trim()) {
            alert('Please Update any one field');
            return;
        }
        try {
            const config = {
                url: `/updateDesignDetails/${edit?._id}`,
                method: "put",
                baseURL: "https://talharconstructions.in/api/admin",
                headers: { "content-type": 'application/json' },
                data: {
                    Description: Description,
                    delight: delight,
                    delightPrice: delightPrice,
                    grand: grand,
                    grandPrice: grandPrice,
                    iconic: iconic,
                    iconicPrice: iconicPrice,
                },
            };

            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                handleClose1();
                getSubCategory();
                setDescription("")
                setDelight("");
                setDelightPrice('');
                setGrand('');
                setGrandPrice('');
                setIconic('');
                setIconicPrice('');
            }
        } catch (error) {
            console.error('Error fetching Designs:', error);
            alert('Failed to fetch SubCategory Details');
        }
    }
    // console.log(edit,"edit");



    //<-------------Add Description ------------->
    //Post Method
    const [AddDescription1, setAddDescription1] = useState('');
    const [delight1, setDelight1] = useState('');
    const [grand1, setGrand1] = useState('');
    const [iconic1, setIconic1] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [date, setDate] = useState(new Date());

    const AddDescriptionDetails = async () => {
        if (!AddDescription1 || !delight1 || !grand1 || !iconic1 || !selectedCategory.trim()) {
            alert('All fields are required');
            return;
        }
        try {
            const config = {
                url: "/postbathroomdetails",
                method: "post",
                baseURL: "https://talharconstructions.in/api/admin",
                headers: { "content-type": 'application/json' },
                data: {
                    AddDescription1,
                    delight1,
                    grand1,
                    iconic1,
                    selectedCategory,
                    date: date.toISOString(), // Use the selected date
                },
            };

            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                handleClose();
                getAddDescriptionDetails();
                setAddDescription1('');
                setDelight1('');
                setGrand1('');
                setIconic1('');
                setSelectedCategory('');
                setDate(new Date()); // Reset date
            }
        } catch (error) {
            console.error('Error fetching Designs:', error);
            alert('Failed to fetch Designs');
        }
    }



    //Get Add Descriptions

    const [data2, setdata2] = useState([]);
    const getAddDescriptionDetails = async () => {
        try {
            const res = await axios.get("https://talharconstructions.in/api/admin/getbathroomdetails");
            if (res.status === 200) {
                setdata2(res.data.success)
            }
        } catch (error) {
            console.error(error);

        }
    }
    useEffect(() => {
        getAddDescriptionDetails();
    }, [])

        // Logic for displaying current items
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = data2?.slice(indexOfFirstItem, indexOfLastItem);
    
        // Logic for displaying page numbers
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(data2?.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }
    
        // Handle page change
        const paginate = (pageNumber) => setCurrentPage(pageNumber);

    console.log("data2", data2);

    //Delete Method

    const deleteAddDescriptionDetails = async (_id) => {
        try {
            let res = await axios.delete(`https://talharconstructions.in/api/admin/deletebathroomdetails/${edit?._id}`);
            if (res.status === 200) {
                alert(res.data.message);
                handleClose2();
                getAddDescriptionDetails([]);
            }
        } catch (error) {
            console.log(error);

        }
    }
    //Update Method
    const UpdateAddDescriptionDetails = async () => {
        if (!AddDescription1 && !delight1 && !grand1 && !iconic1 && !selectedCategory.trim()) {
            alert('Please Update any one field');
            return;
        }
        try {
            const config = {
                url: `/updatebathroomdetails/${edit?._id}`,
                method: "put",
                baseURL: "https://talharconstructions.in/api/admin",
                headers: { "content-type": 'application/json' },
                data: {
                    AddDescription1: AddDescription1,
                    delight1: delight1,
                    grand1: grand1,
                    iconic1: iconic1,

                },
            };

            let res = await axios(config);
            if (res.status === 200) {
                alert(res.data.success);
                handleClose1();
                getAddDescriptionDetails();
                setAddDescription1('');
                setDelight1('');
                setGrand1('');
                setIconic1('');
                setSelectedCategory('');
            }
        } catch (error) {
            console.error('Error fetching Designs:', error);
            alert('Failed to fetch Designs');
        }
    }

    return (
        <div>
            <Row>
                <div className='p-3 d-flex gap-3'>
                    <Button style={{ background: "rgb(3, 146, 182) ", color: "white" }}
                        onClick={() => {
                            setCategories(true);
                            setSubCategories(false);
                            setAddDescription(false);
                        }} >Categories</Button>
                    <Button style={{ background: "rgb(3, 146, 182) ", color: "white" }}
                        onClick={() => {
                            setCategories(false);
                            setSubCategories(true);
                            setAddDescription(false);
                        }} >Category Heading</Button>
                    <Button style={{ background: "rgb(3, 146, 182) ", color: "white" }}
                        onClick={() => {
                            setCategories(false);
                            setSubCategories(false);
                            setAddDescription(true);
                        }} >AddDescription</Button>
                </div>
            </Row>

            {Categories ? (<>
                <div className='Design'>
                    <h1>Categories</h1>
                    <Button className='add-buttons' onClick={handleShow}>+Add</Button>


                    <Modal show={show} onHide={handleClose} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Categories </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='mt-2'>
                                    <label htmlFor="">Category Name</label>
                                    <input type="text"
                                        className='designInput'
                                        placeholder='Category Name...'
                                        style={{ width: "100%" }}
                                        id="input-border"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddCategoy}>
                                Add
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Category Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems1?.map((item, index) => {
                                return (
                                    <tr key={item._id}>
                                        <td>{++index}.</td>
                                        <td> {item.categoryName}</td>
                                        <td>
                                            <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                                onClick={() => {
                                                    handleShow1();
                                                    setEdit(item)
                                                }} /> /
                                            <MdDelete style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                                onClick={() => {
                                                    handleShow2();
                                                    setEdit(item);
                                                }} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
 {/* Pagination controls */}
            <nav>
                <ul className='pagination'>
                    {pageNumbers1.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate1(number)} className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
                    <Modal show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
                                <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='paragraphs'>Are you Sure?</p>
                            <p className='paragraphs'>You want to delete this data?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose2}>
                                Close
                            </Button>
                            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deleteCategorydetails}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={show1} onHide={handleClose1}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
                                Edit Category
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div>
                                <div className='mt-2'>
                                    <label htmlFor="">Category Name</label>
                                    <input type="text"
                                        className='designInput'
                                        placeholder='Category Name...'
                                        style={{ width: "100%" }}
                                        id="input-border"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                    />
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose1}>
                                Close
                            </Button>
                            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={UpdateCategoryDetails}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </>) : (<></>)}

            {SubCategories ? (<>
                <div className='Design'>
                    <h1>Category Heading</h1>
                    <Button className='add-buttons'
                        onClick={handleShow}>+Add</Button>


                    <Modal show={show} onHide={handleClose} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Sub Categories </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='mt-2'>
                                    <label htmlFor="">Description</label>
                                    <input type="text"
                                        className='designInput'
                                        placeholder='SubCategories...'
                                        style={{ width: "100%" }}
                                        id="input-border"
                                        value={Description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Delight</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Delight...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={delight}
                                                onChange={(e) => setDelight(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Delight Price</label>
                                            <input type="number" min={0}
                                                className='designInput'
                                                placeholder='Delight Price...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={delightPrice}
                                                onChange={(e) => setDelightPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Grand</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Grand...'
                                                style={{ width: "100%" }}
                                                id="input-border"

                                                value={grand}
                                                onChange={(e) => setGrand(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Grand Price</label>
                                            <input type="number"
                                                className='designInput'
                                                placeholder='Grand Price...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={grandPrice}

                                                min={0}
                                                onChange={(e) => setGrandPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Iconic</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Iconic...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={iconic}
                                                onChange={(e) => setIconic(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Iconic Price</label>
                                            <input type="number"
                                                className='designInput'
                                                placeholder='Iconic Price...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={iconicPrice}
                                                onChange={(e) => setIconicPrice(e.target.value)}
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddSubCategory}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Sl.No</th>
                                <th>Description</th>
                                <th>Delight</th>
                                <th>Delight price</th>
                                <th>Grand</th>
                                <th>Grand Price</th>
                                <th>Iconic</th>
                                <th>Iconic Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => {
                                return (

                                    <tr key={item._id}>
                                        <td>{++index}.</td>
                                        <td>{item.Description}</td>
                                        <td>{item.delight}</td>
                                        <td>{item.delightPrice}</td>
                                        <td>{item.grand}</td>
                                        <td>{item.grandPrice}</td>
                                        <td>{item.iconic}</td>
                                        <td>{item.iconicPrice}</td>

                                        <td>
                                            <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                                onClick={() => {
                                                    handleShow1();
                                                    setEdit(item)
                                                }} /> /
                                            <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                                                onClick={() => {
                                                    handleShow2();
                                                    setEdit(item)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table>

                    <Modal show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
                                <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='paragraphs'>Are you Sure?</p>
                            <p className='paragraphs'>You want to delete this data?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose2}>
                                Close
                            </Button>
                            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deleteSubCategory}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={show1} onHide={handleClose1} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>SubCategory </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className='mt-2'>
                                    <label htmlFor="">Description</label>
                                    <input type="text"
                                        className='designInput'
                                        placeholder='SubCategories...'
                                        style={{ width: "100%" }}
                                        id="input-border"
                                        value={Description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                                <div className='row'>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Delight</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Delight...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={delight}
                                                onChange={(e) => setDelight(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Delight Price</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Delight Price...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={delightPrice}
                                                onChange={(e) => setDelightPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Grand</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Grand...'
                                                style={{ width: "100%" }}
                                                id="input-border"

                                                value={grand}
                                                onChange={(e) => setGrand(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Grand Price</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Grand Price...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={grandPrice}
                                                onChange={(e) => setGrandPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Iconic</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Iconic...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={iconic}
                                                onChange={(e) => setIconic(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='mt-2'>
                                            <label htmlFor="">Iconic Price</label>
                                            <input type="text"
                                                className='designInput'
                                                placeholder='Iconic Price...'
                                                style={{ width: "100%" }}
                                                id="input-border"
                                                value={iconicPrice}
                                                onChange={(e) => setIconicPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose1}>
                                Close
                            </Button>
                            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={UpdateSubCategoryDetails}>
                                Update
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>

            </>) : (<></>)}

            {AddDescription ? (<>
                <div className='Design'>
                <h1>Add Description</h1>
                <Button className='add-buttons' onClick={handleShow}>+Add</Button>

                <Modal show={show} onHide={handleClose} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Add Description </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div>
                            <div className='mt-2'>
                                <label htmlFor="">Category</label>
                                <select
                                    id="category"
                                    className='designInput'
                                    style={{ width: "100%" }}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                >
                                    <option value="">Select an option</option>
                                    {data1?.map((item) => (
                                        <option value={item.categoryName} key={item.categoryName}>{item.categoryName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='mt-2'>
                                <label htmlFor="">Description</label>
                                <input type="text"
                                    className='designInput'
                                    placeholder='SubCategories...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={AddDescription1}
                                    onChange={(e) => setAddDescription1(e.target.value)}
                                />
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Delight</label>
                                <textarea
                                    type="text"
                                    className='designInput'
                                    placeholder='Delight...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={delight1}
                                    onChange={(e) => setDelight1(e.target.value)}
                                ></textarea>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Grand</label>
                                <textarea
                                    type="text"
                                    className='designInput'
                                    placeholder='Grand...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={grand1}
                                    onChange={(e) => setGrand1(e.target.value)}
                                ></textarea>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Iconic</label>
                                <textarea
                                    type="text"
                                    className='designInput'
                                    placeholder='Iconic...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={iconic1}
                                    onChange={(e) => setIconic1(e.target.value)}
                                ></textarea>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Date</label>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                    className='designInput'
                                    dateFormat='yyyy-MM-dd'
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={AddDescriptionDetails}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Delight</th>
                        <th>Grand</th>
                        <th>Iconic</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.map((item, index) => (
                        <tr key={item._id}>
                            <td>{indexOfFirstItem + index + 1}</td>
                            <td>
                                <div style={{ width: "auto", height: "70px", overflow: "hidden", overflowY: "scroll" }}>
                                    {item?.selectedCategory}
                                </div>
                            </td>
                            <td>
                                <div style={{ width: "auto", height: "100px", overflow: "hidden", overflowY: "scroll" }}>
                                    {item.AddDescription1}
                                </div>
                            </td>
                            <td>
                                <div style={{ width: "auto", height: "70px", overflow: "hidden", overflowY: "scroll" }}>
                                    {item.delight1}
                                </div>
                            </td>
                            <td>
                                <div style={{ width: "auto", height: "70px", overflow: "hidden", overflowY: "scroll" }}>
                                    {item.grand1}
                                </div>
                            </td>
                            <td>
                                <div style={{ width: "auto", height: "70px", overflow: "hidden", overflowY: "scroll" }}>
                                    {item.iconic1}
                                </div>
                            </td>
                            <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                            <td>
                                <div className='d-flex justify-content-center'>
                                    <FaRegEdit style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow1();
                                            setEdit(item)
                                        }} /> /
                                    <MdDelete style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow2();
                                            setEdit(item)
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <nav>
                <ul className='pagination'>
                    {pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate(number)} className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>

                {/* Modal for delete confirmation */}
                <Modal show={show2} onHide={handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
                            <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='paragraphs'>Are you sure?</p>
                        <p className='paragraphs'>You want to delete this data?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={deleteAddDescriptionDetails}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for edit */}
                <Modal show={show1} onHide={handleClose1} size="md">
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>Edit Description</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className='mt-2'>
                                <label htmlFor="">Category</label>
                                <select
                                    id="category"
                                    className='designInput'
                                    style={{ width: "100%" }}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                >
                                    <option value="">Select an option</option>
                                    {data1?.map((item) => (
                                        <option value={item.categoryName} key={item.categoryName}>{item.categoryName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='mt-2'>
                                <label htmlFor="">Description</label>
                                <input type="text"
                                    className='designInput'
                                    placeholder='SubCategories...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={AddDescription1}
                                    onChange={(e) => setAddDescription1(e.target.value)}
                                />
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Delight</label>
                                <textarea
                                    type="text"
                                    className='designInput'
                                    placeholder='Delight...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={delight1}
                                    onChange={(e) => setDelight1(e.target.value)}
                                ></textarea>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Grand</label>
                                <textarea
                                    type="text"
                                    className='designInput'
                                    placeholder='Grand...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={grand1}
                                    onChange={(e) => setGrand1(e.target.value)}
                                ></textarea>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Iconic</label>
                                <textarea
                                    type="text"
                                    className='designInput'
                                    placeholder='Iconic...'
                                    style={{ width: "100%" }}
                                    id="input-border"
                                    value={iconic1}
                                    onChange={(e) => setIconic1(e.target.value)}
                                ></textarea>
                            </div>

                            <div className='mt-2'>
                                <label htmlFor="">Date</label>
                                <DatePicker
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                    className='designInput'
                                    dateFormat='yyyy-MM-dd'
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose1}>
                            Close
                        </Button>
                        <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={UpdateAddDescriptionDetails}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            </>) : (<></>)}

        </div>
    )
}

export default ConstructionPackages