import React, { useEffect, useState } from "react";
import { Button, Row, Table, Modal } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import './Admin.css'
import { IoWarningOutline } from "react-icons/io5";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import moment from "moment";

const Services = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [description, setdescription] = useState("");
  const handleDescription = (e, editor) => {
    const data = editor.getData();
    setdescription(data);
  };

  //remove html tags from the table
  const stripHtmlTags = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString;
    return div.textContent || div.innerText || '';
  };

  // condition 
  const [Service, setService] = useState(true);

  //Post tMethod
  const [serviceimage, setServiceImage] = useState('')
  const [serviceText, setServiceText] = useState('')
  const [Description, setDescription] = useState('')

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  //Post Method
  const addService = async () => {

    const formdata = new FormData()

    formdata.append('serviceImage', serviceimage);
    formdata.append('serviceName', serviceText);
    formdata.append('serviceDescription', Description);

    if(!serviceimage || !serviceText || !Description.trim()){
      alert("All Fields Are require");
      return;
    }

    try {
      const config = {
        url: "/postservice",
        method: "post",
        baseURL: "https://talharconstructions.in/api/services",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)

      if (res.status === 200) {
        alert(res.data.success);
        handleClose();
        getBannerData();
        // after updated the inputfields will be clear
        setServiceText('');
        setDescription('');
      }

    }
    catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }


  //Get Method
  const [data, setdata] = useState([]);
  console.log(data, "Service")
  const getBannerData = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/services/getservice"
      );      if (res.status === 200) {
        setdata(res.data.success);
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
      alert('Failed to fetch banners');
    }
  };

  useEffect(() => {
    getBannerData();
  }, [])


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  // Logic for displaying current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  // Logic for displaying page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
      pageNumbers.push(i);
  }

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  //Delete Method
  const [edit, setEdit] = useState({})
  console.log(edit, "sditeeee")

  const deletData = async (_id) => {
    try {
      let res = await axios.delete(`https://talharconstructions.in/api/services/deleteservice/${edit?._id}`)
      if (res.status === 200) {
        alert("Deleted Successfully")
        handleClose2()
        getBannerData([])
      }
    } catch (error) {
      console.log(error)
    }
  }


  //Edit Banner
  console.log(edit, "edit");
  useEffect(() => {
    if (edit) {
      setServiceText(edit?.serviceName || "")
      setDescription(edit?.serviceDescription || "")
    }
  }, [])
  const editService = async () => {
    const formdata = new FormData()
    formdata.append('serviceImage', serviceimage);
    formdata.append('serviceName', serviceText);
    formdata.append('serviceDescription', Description)

     // Check if at least one field is provided
  if (!serviceimage && !serviceText && !Description) {
    alert("Please update any one field.");
    return;
  }
    try {
      const config = {
        url: `/updateservice/${edit?._id}`,
        method: "put",
        baseURL: "https://talharconstructions.in/api/services",
        Headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config)
      if (res.status === 200) {
        alert(res.data.success);
        handleClose1();
        getBannerData()
        // after updated the inputfields will be clear
        setServiceText('');
        setDescription('');
      }

    } catch (error) {
      console.error('Error adding banner:', error);
      alert('Failed to add banner');
    }
  }




  return (
    <div>

      {/* <Row >
        <input type="search" placeholder="search here...." className='banner-input' />
        <div className='serch-icon'>  <FaSearch /></div>
      </Row> */}


      <Row>
        <div className='p-3 d-flex gap-3'>
          <Button variant="" style={{ background: "rgb(3, 146, 182) ", color: "white" }}
            onClick={() => {
              setService(true);
            }}
          > Services</Button>
        </div>
      </Row>
      <hr />

      {Service ? (<>
        <h1 className='h1-style'>Services</h1>
        <Button className='add-buttons' onClick={handleShow}>
          +Add
        </Button>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#080874" }}>
              Services
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class="mb-2">
              <label htmlFor="inputGroupFile03">Service Image</label>
              <input type="file"
                class="form-control"
                id="inputGroupFile03"
                aria-describedby="inputGroupFileAddon05"
                aria-label="Upload"
                accept="image/*"
                onChange={(e) => setServiceImage(e.target.files[0])} />

            </div>

            <div>
              {/* <label htmlFor="">Service Name</label> */}
              <div class="mt-3">
                <input type="text"
                  placeholder='Service Name.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={serviceText}
                  onChange={(e) => setServiceText(e.target.value)} />
              </div>
            </div>
            <div>
              {/* <label htmlFor="">Description</label> */}
              <div class="mt-3">
                {/* <input type="text"
                  placeholder='Description.....'
                  style={{ width: "100%" }}
                     id="input-border"
                /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <Button style={{ backgroundColor: '#080874' }} onClick={addService}>
              Add
            </Button>



          </Modal.Footer>
        </Modal>

        {/* Delete banner modal */}
        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#080874" }}>
              <h3 style={{ color: "green" }}>WARNING <IoWarningOutline /></h3>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p className='paragraphs'>Are you Sure?</p>
            <p className='paragraphs'>You want to delete this data?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button style={{ backgroundColor: '#080874' }} onClick={deletData}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* edit banner modal */}
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "rgb(3, 146, 182)" }}>
              Edit Services
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className='mb-2'>
              <label htmlFor="inputGroupFile03">Service Image</label>
              <input type="file"
                class="form-control"
                id="inputGroupFile03"
                aria-describedby="inputGroupFileAddon05"
                aria-label="Upload"
                accept="image/*"
                onChange={(e) => setServiceImage(e.target.files[0])} />

            </div>

            <div class="mb-2">
              <label htmlFor="">Service Name</label>
              <div>
                <input type="text"
                  placeholder='Service Name.....'
                  style={{ width: "100%" }}
                  id="input-border"
                  value={serviceText}
                  onChange={(e) => setServiceText(e.target.value)} />
              </div>
            </div>
            <div >
              <label htmlFor="">Description</label>
              <div>
                {/* <input type="text"
                  placeholder='description....'
                  style={{ width: "100%" }} 
                  id="input-border"/> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={Description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button style={{ backgroundColor: 'rgb(3, 146, 182)' }} onClick={editService}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <div>
          <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Sl.No</th>
                        <th>Image</th>
                        <th>Service Name</th>
                        <th style={{ width: "50%" }}>Description</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems?.map((item, index) => (
                        <tr key={item._id}>
                            <td>{indexOfFirstItem + index + 1}.</td>
                            <td>
                                <div>
                                    <img
                                        src={`https://talharconstructions.in/Partnersimage/${item?.serviceImage}`}
                                        alt="Serviceimage"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </div>
                            </td>
                            <td>{item.serviceName}</td>
                            <td>
                                <div style={{ width: 'auto', height: "100px", overflow: "hidden", overflowY: "scroll" }}>
                                    {stripHtmlTags(item.serviceDescription)}
                                </div>
                            </td>
                            <td>{moment(item?.createdAt).format("DD-MM-YY")}</td>
                            <td>
                                <div className="d-flex gap-2 justify-content-center">
                                    <FaRegEdit
                                        style={{ cursor: 'pointer', color: "green", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow1();
                                            setEdit(item);
                                        }}
                                    /> /
                                    <MdDelete
                                        style={{ cursor: 'pointer', color: "rgb(3, 146, 182)", fontSize: "30px" }}
                                        onClick={() => {
                                            handleShow2();
                                            setEdit(item);
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination controls */}
            <nav>
                <ul className='pagination'>
                    {pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate(number)} className='page-link'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>

        </div>
      </>) : (<></>)}

    </div>
  )
}

export default Services