import React, { useEffect, useState } from "react";
import "../Styles/Home.css";
import Carousel from "react-bootstrap/Carousel";
import { Container } from "react-bootstrap";
import { Row, Card, Button } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Aos from "aos";
import axios from "axios";
import { FaArrowRightLong } from "react-icons/fa6";
import { ImQuotesLeft } from "react-icons/im";
import { FaSquareWhatsapp } from "react-icons/fa6";

const Home = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    Aos.init();
    // window.scroll(0, 0)
  });

  const [ViewMore1, setViewMore1] = useState(false); //About us
  const [ViewMore4, setViewMore4] = useState(false); //Our Services
  const [ViewMore3, setViewMore3] = useState(false); //what Client Say
  // const [ViewMore5, setViewMore5] = useState(false);

  // Get Method
  const [data, setdata] = useState([]);
  const getBannerData = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/banner/getbanner');
      if (res.status === 200) {
        setdata(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  const [ABoutUsDatat, setABoutUsDatat] = useState([]);
  const getAboutus = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/webmanagement/getaboutusdetails"
      );
      if (res.status === 200) {
        setABoutUsDatat(res.data.status);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  //get method
  const [partnersimg, setPartnersimg] = useState([]);

  const getData = async () => {
    try {
      let res = await axios.get(
        "https://talharconstructions.in/api/partners/getpartner"
      );
      if (res.status === 200) {
        setPartnersimg(res.data.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [viewMoreStates, setViewMoreStates] = useState(
    Array(partnersimg?.length).fill(false)
  );

  const handleViewMoreToggle = (index) => {
    const updatedStates = [...viewMoreStates];
    updatedStates[index] = !updatedStates[index];
    setViewMoreStates(updatedStates);
  };

  useEffect(() => {
    getData();
  }, []);
  // const htmlContent = partnersimg?.[0]?.partnersDescription;

  useEffect(() => {
    getBannerData();
    getAboutus();
  }, []);

  //Our services
  //Get Method

  const [servicesdata, setServicesdata] = useState([]);

  const getServicesdata = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/services/getservice"
      );
      if (res.status === 200) {
        setServicesdata(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      alert("Failed to fetch banners");
    }
  };

  useEffect(() => {
    getServicesdata();
  }, []);
  // const htmlContent1 = servicesdata?.[0]?.serviceDescription;

  // What Client Say
  //get method
  const [clientSay, setClientSay] = useState([]);

  const getClient = async () => {
    try {
      const res = await axios.get('https://talharconstructions.in/api/webmanagement/getwhatclientsaysdetails');
      if (res.status === 200) {
        setClientSay(res.data.success); // Ensure the key matches the response
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
      alert("Failed to fetch client details");
    }
  
  };

 
 
  useEffect(() => {
    getClient();
  }, []);
  // const htmlContent3 = clientSay?.[0]?.clientDescription;
  return (
    <div>
      <div>
        <Carousel>
          {data?.map((item) => {
            return (
              <Carousel.Item>
                <img
                  src={`https://talharconstructions.in/Bannerimage/${item?.bannerimg}`}
                  class="slider-img"
                  alt="..."
                />
                <Carousel.Caption>
                  <h1 className="carousel-style">{item?.bannertext}</h1>
                  {/* <h2 className='carousel-style'>RESPONSIVE AND ELEGANT DESIGN</h2> */}
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
          {/* <Carousel.Item>
            <img src="../Assets/building.jpg" class="slider-img" alt="..." />
            <Carousel.Caption>
              <h1 className='carousel-style'>WE ARE HERE FOR YOUR ARCHITECTURE DESIGN</h1>
              <h2 className='carousel-style'>RESPONSIVE AND ELEGANT DESIGN</h2>
              
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src="../Assets/slider3.jpg" class="slider-img" alt="..." />
            <Carousel.Caption>
              <h1 className='carousel-style'>WE ARE HERE FOR YOUR ARCHITECTURE DESIGN</h1>
              <h2 className='carousel-style'>RESPONSIVE AND ELEGANT DESIGN</h2>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img src="../Assets/pngtree.jpg " class="slider-img" alt="..." />
            <Carousel.Caption>
              <h1 className='carousel-style'>WE ARE HERE FOR YOUR ARCHITECTURE DESIGN</h1>
              <h2 className='carousel-style'>RESPONSIVE AND ELEGANT DESIGN</h2>
            </Carousel.Caption>
          </Carousel.Item>  */}
        </Carousel>
      </div>
      <div className="fixed-icon">
        <a
          href="https://wa.me/8310330060" // Replace with the correct phone number
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Chat with us on WhatsApp"
        >
          <FaSquareWhatsapp style={{ fontSize: "30px", color: "white" }} />
        </a>
      </div>
      <div>
        {/* Aout us Company */}
        <Container>
          <div className="row mt-4 mb-4">
            <div className="col-md-5">
              <div data-aos="zoom-in" data-aos-duration="3000">
                <img
                  className="about-img"
                  src={`https://talharconstructions.in/Aboutusimage/${ABoutUsDatat?.[0]?.aboutusimg}`}
                  alt=""
                />
              </div>
            </div>

            <div className="col-md-7">
              <div>
                <h4 className="main-title"> ---About us Company---</h4>
                <h1 style={{ textAlign: "left" }}>
                  <b>We Are Always Think On Your Dream</b>
                </h1>
              </div>

              <p
                className="about-content"
                data-aos="fade-down"
                data-aos-duration="3000"
              >
                {ViewMore1 ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div>${ABoutUsDatat?.[0]?.description}</div>`
                          ?.split("")
                          ?.slice(
                            0,
                            `<div>${ABoutUsDatat?.[0]?.description}</div>`?.split(
                              ""
                            )?.length
                          )
                          ?.join(""),
                      }}
                    />
                    <div
                      style={{ color: "blue" }}
                      onClick={() => setViewMore1(false)}
                    >
                      View less
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div>${ABoutUsDatat?.[0]?.description}</div>`
                          ?.split("")
                          ?.slice(0, 200)
                          ?.join(""),
                      }}
                    />
                    <div
                      className="more-detailes"
                      style={{ color: "blue" }}
                      onClick={() => setViewMore1(true)}
                    >
                      <a href="/aboutus">View More Details</a>
                    </div>
                  </>
                )}

                {/* <div dangerouslySetInnerHTML={{ __html: `<div>${ABoutUsDatat?.[0]?.description}</div>` }} /> */}
              </p>
              {/* <div className='more-detailes'><a href="/aboutus">View More Details</a></div> */}
            </div>
          </div>
        </Container>
      </div>

      {/* Our Services */}
      <section className="section-contaner">
        <h4 style={{ color: "white" }}>----Our Services----</h4>
        <h1 style={{ fontSize: "45px" }}>
          <b>The Best Services For You</b>
        </h1>
        <Container>
          <div className="row mt-4 mb-4 align-items-center justify-content-center m-auto">
            <OwlCarousel className="owl-theme" loop margin={10} {...options}>
              {servicesdata?.map((item) => (
                <div className="col-md-12 mb-2" key={item.id}>
                  {" "}
                  {/* Added key for unique identification */}
                  <div className="img-container">
                
                   <img
                      className="img"
                      src={`https://talharconstructions.in/Partnersimage/${item?.serviceImage}`}
                      alt="Service image"
                    />
                
                    <div className="img-overlay">
                      <h4 style={{ color: "white", marginTop: "15px" }}>
                 
                        {item?.serviceName}
                      </h4>
                      <p>
                        {ViewMore4 ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div>${item?.serviceDescription}</div>`
                                  ?.split("")
                                  ?.slice(
                                    0,
                                    `<div>${item?.serviceDescription}</div>`?.split(
                                      ""
                                    )?.length
                                  )
                                  ?.join(""),
                              }}
                            />
                            <div
                              style={{ color: "white" }}
                              onClick={() => setViewMore4(false)}
                            >
                              View less
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div>${item?.serviceDescription}</div>`
                                  ?.split("")
                                  ?.slice(0, 200)
                                  ?.join(""),
                              }}
                            />
                            <div
                              style={{ color: "white" }}
                              onClick={() => setViewMore4(true)}
                            >
                              View More
                            </div>
                          </>
                        )}
                        {/* <div dangerouslySetInnerHTML={{ __html: `<div>${item?.serviceDescription}</div>` }} />  */}
                      </p>
                      {/* <a className='anchortag' href="/service">Read More <FaArrowRightLong /></a> */}
                    </div>
                  </div>
                  <h1
                    className="img-caption-bottom"
                    style={{ fontSize: "30px" }}
                  >
                     
                        <a style={{textDecoration:"none", color:"white"}} href="/service">
                         {item?.serviceName}
                         </a>
                  
                     
                  </h1>
                </div>
              ))}
              {/* Repeat the block as needed */}
            </OwlCarousel>
          </div>
        </Container>
      </section>

      {/* 
      <Container>
        <h1 className='main-title'>Our Services</h1>
        <div className='image-grow'>
          <div className='row mt-2 mb-3' >
            {ourteamImg?.map((item) => (
              <div className='col-md-3 mb-2' key={item.id}> 
                <div className='img-container'>
                  <img className='img' src={`https://talharconstructions.in/Ourteamimage/${item?.ourteamimg}`} 
                  alt={item?.ourteamName} />
                  <h3 className='img-caption'>{item?.ourteamName}</h3>
                </div>
              </div>
            ))}
             <div className='col-md-3 mb-2'>
              <div className='img-container'>
                <img className='img' src="../assets/hm1.jpg" alt="" />
                <h3 className='img-caption'>Construction</h3>
              </div>
            </div>

            <div className='col-md-3 mb-2'>
              <div className='img-container'>
                <img className='img' src="../assets/imgtext.jpg" alt="" />
                <h3 className='img-caption'>Interior</h3>
              </div>
            </div>
            
            <div className='col-md-3 mb-2'>
              <div className='img-container'>
                <img className='img' src="../assets/service C.avif" alt="" />
                <h3 className='img-caption'>Construction</h3>
              </div>
            </div>

            <div className='col-md-3 mb-2'>
              <div className='img-container'>
                <img className='img' src="../assets/imgtext4.webp" alt="" />
                <h3 className='img-caption'>Interior</h3>
              </div>
            </div> 
          </div>
        </div>
      //our services </Container> */}

      {/* More Contact detailes */}
      <Container fluid className="p-0 mt-5 mb-5">
        <div className="image-container">
          <img
            src="../Assets/freeConslt.jpg"
            alt="Background"
            className="img-fluid"
          />
          <div className="text-overlay">
            <h6>Get Consultation------</h6>
            <h2> Get A Free Consultaion</h2>
            <h2>
              Contact US <span style={{ color: "  orange" }}>!!</span>
            </h2>
            <div className="text-center mt-4">
              <Button variant="primary">
                {" "}
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/contact"
                >
                  Contact Us
                </a>
              </Button>
            </div>
          </div>
        </div>
      </Container>

      {/* <div>
    <div>
        <h2 className='main-title'>What Client Say?</h2>
      </div>
      <Container>
        <div className='row mt-2 mb-3'>
          <div className='col-md-8'>
            <div>
              <p className='choose-content' data-aos="fade-down" data-aos-duration="3000">
                ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
                officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
                itaque natus laboriosam quasi quo maxime?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
                officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
                itaque natus laboriosam qLoremuasi quo maxime?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
                officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
                itaque natus laboriosam quasi quo maxime?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
                officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
                itaque natus laboriosam quasi quo maxime?
                ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
                officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
                itaque natus laboriosam quasi quo maximee?</p>


            </div>
          </div>
          <div className='col-md-4' >
            <div id='img-container' data-aos="zoom-in" data-aos-duration="3000">
              <img className='choose-img' src="../Assets/choose.jpg" alt="" />
              <img className='overly-image' src="../Assets/construction.jpg" alt="" />
            </div>
          </div>
        </div>
      </Container> 
    </div> */}

      {/* What Client Say?? */}
      <section className="section-container mt-4 mb-4">
  <div className="text-center">
    <h4 style={{ color: "white" }}>---TESTIMONIAL---</h4>
    <h1>
      <b>What Clients Say?</b>
    </h1>
  </div>

  <Container>
    <div
      className="row mt-3 mb-3"
      data-aos="zoom-in"
      data-aos-duration="3000"
      style={{ background: "white", padding: "20px" }}
    >
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        {...options}
        style={{ color: "black" }}
      >
        {clientSay?.map((item, index) => (
          <div key={index} className="item">
            <div className="testimonial-content">
              <p>
                {ViewMore3 ? (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.clientDescription?.slice(0, 100) + '...',
                      }}
                    />
                    <div
                      onClick={() => setViewMore3(false)}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      View Less
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.clientDescription,
                      }}
                    />
                    <div
                      onClick={() => setViewMore3(true)}
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      View More
                    </div>
                  </>
                )}
              </p>
            </div>
            <div className="testimonial-author">
              {/* Uncomment and use the following line if you want to display the image */}
              {/* <img src={`https://talharconstructions.in/Aboutusimage/${item?.clientImage}`} alt="testimonial" className='testimonial_image' /> */}
              <h4 style={{ color: "black", textAlign: "right" }}>
                {item?.clientName}
              </h4>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  </Container>
</section>



      {/* Project Done */}
      <Container>
        <div id="background">
          <div
            className="row mt-2 mb-3"
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <div className="col-md-3 mb-2">
              <h1>20+</h1>
              <h4>Site Engineer'd </h4>
            </div>

            <div className="col-md-3 mb-2">
              <h1>5+</h1>
              <h4>Architecture </h4>
            </div>

            <div className="col-md-3 mb-2">
              <h1>200+</h1>
              <h4>Employees </h4>
            </div>

            <div className="col-md-3 mb-2">
              <h1 className="">280+</h1>
              <h4>Expert Team Members</h4>
            </div>
          </div>
        </div>
      </Container>

      {/* latest project //Instead of partenrs */}
      <section className="section-contaners mt-4 mb-3">
        <div className="mb-5">
          <h4 className="main-title">----Latest Projects----</h4>
          <h1 style={{ textAlign: "center" }}>
            <b>Crafting Quality Structures,Creating</b>
          </h1>
          <h1>
            <b> Lasting Impressions.</b>
          </h1>
        </div>
        <Container>
          <div className="row mt-2 mb-3 align-items-center justify-content-center m-auto">
            <OwlCarousel className="owl-theme" loop margin={10} {...options}>
              {partnersimg?.map((item, index) => (
                <div className="col-md-12 mb-2" key={item.id}>
                  <div className="img-container position-relative">
                    <img
                      src={`https://talharconstructions.in/Partnersimage/${item?.partnersimg}`}
                      alt="Partner image"
                      className="img"
                    />
                    <div className="img-overlay">
                      <h1 style={{ color: "white" }} className="img-caption">
                        {item.partnerName}
                      </h1>
                      <p
                        className="img-description"
                        style={{
                          width: "auto",
                          height: "100%",
                          overflow: "hidden",
                          overflowY: "visible",
                        }}
                      >
                        {viewMoreStates[index] ? (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<div>${item?.partnersDescription}</div>`,
                              }}
                            />
                            <div
                              style={{ color: "white", cursor: "pointer" }}
                              onClick={() => handleViewMoreToggle(index)}
                            >
                              View Less
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  `<div>${item?.partnersDescription}</div>`
                                    .split("")
                                    .slice(0, 100)
                                    .join(""),
                              }}
                            />
                            <div
                              style={{ color: "white", cursor: "pointer" }}
                              onClick={() => handleViewMoreToggle(index)}
                            >
                              View More
                            </div>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Home;
