import React, { useState, useEffect } from "react";
import "../Styles/contact.css";
import {
  FaAddressBook,
  FaPhoneAlt,
  FaWhatsapp,
  FaFacebook,
} from "react-icons/fa";
import { AiTwotoneMail } from "react-icons/ai";
import { FaSquareInstagram, FaSquareWhatsapp } from "react-icons/fa6";
import { Container } from "react-bootstrap";
import axios from "axios";

const Contactus = () => {
  const [name, setName] = useState("");
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});

  // Form validation
  const validateForm = () => {
    const newErrors = {};

    // Name validation: Only letters and spaces allowed
    if (!name) {
      newErrors.name = "Name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(name)) {
      newErrors.name = "Name must contain only letters and spaces";
    }

    // Phone number validation: Must start with '7' and be up to 10 digits
    if (!/^[789]\d{0,9}$/.test(phoneNumber1)) {
      newErrors.phoneNumber1 = "Phone number must start with '7' and be up to 10 digits";
    }

    // Email validation: Must contain "@" and "."
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      newErrors.email =
        'Valid email address is required (must contain "@" and ".")';
    }

    // Message validation
    if (!message) {
      newErrors.message = "Message is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setName(value);
    }
  };

  const handleChange1 = (e) => {
    const value = e.target.value;
    // Allow only numbers
    if (/^[0-9]*$/.test(value)) {
      setPhoneNumber1(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    try {
      const formdata = new FormData();
      formdata.append("name", name);
      formdata.append("phoneNumber", phoneNumber1);
      formdata.append("email", email);
      formdata.append("message", message);

      const config = {
        url: "/postenquiries",
        method: "post",
        baseURL: "https://talharconstructions.in/api/enquiries",
        headers: { "content-type": "application/json" },
        data: formdata,
      };

      const res = await axios(config);

      if (res.status === 200) {
        setSuccessMessage("Thank you! Your message has been sent.");
        getContactDetails('');
        setName("");
        setPhoneNumber1("");
        setEmail("");
        setMessage("");
        setErrors({});
        setErrorMessage(""); // Clear error message if any
        alert("Contact Details Submitted Successfully");
        return;
      }
    } catch (error) {
      console.error("Error adding enquiry:", error);
      setErrorMessage("Input Fields Are Requird. Please try again.");
    }
  };

  const [data, setData] = useState([]);

  // Get Contact Details
  const getContactDetails = async () => {
    try {
      const res = await axios.get(
        "https://talharconstructions.in/api/contactus/getcontactusdetails"
      );
      if (res.status === 200) {
        setData(res.data.success);
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
      alert("Failed to fetch contact details");
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);

  return (
    <div>
      <div className="aboutus-container">
        <div className="image-wrapper">
          <img
            className="aboutus-img"
            src="../Assets/building.jpg"
            alt="about us image"
          />
          <div className="text-overlay">
            <h1>Contact Us</h1>
          </div>
          <div className="link-about">
            <a className="home" href="/">Home</a>/<a href="/contact">Contact Us</a>
          </div>
        </div>
        <div className="fixed-icon">
          <a
            href="https://wa.me/8310330060"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Chat with us on WhatsApp"
          >
            <FaSquareWhatsapp style={{ fontSize: "30px", color: "white" }} />
          </a>
        </div>
      </div>

      <Container>
        <div className="row mt-2">
          <div className="col-md-6 mb-2">
            <form action="" className="form-group mt-3" onSubmit={handleSubmit}>
              <h4>Let's Talk</h4>

              <label htmlFor="name">Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                id="name"
                name="name"
                value={name}
                onChange={handleNameChange}
              />
              {errors.name && <p className="text-danger">{errors.name}</p>}

              <label htmlFor="phone">Phone Number</label>
              <input
                type="number"
                placeholder="Enter your phone number"
                id="phone"
                name="phone"
                value={phoneNumber1}
                onChange={handleChange1}
              />
              {errors.phoneNumber1 && (
                <p className="text-danger">{errors.phoneNumber1}</p>
              )}

              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="text-danger">{errors.email}</p>}

              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                placeholder="Directly message us"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {errors.message && (
                <p className="text-danger">{errors.message}</p>
              )}

              <button type="submit">Submit</button>
              {successMessage && (
                <p className="text-success">{successMessage}</p>
              )}
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
            </form>
          </div>

          {data?.map((item) => (
            <div className="col-md-6 mb-2 mt-3" key={item._id}>
              <div className="card mb-2 card-design">
                <div className="card-body">
                  <div className="d-flex gap-3">
                    <FaAddressBook
                      style={{ color: "#4798bd", fontSize: "20px" }}
                    />
                    <div
                      className="align-items-left"
                      style={{ textAlign: "left" }}
                    >
                      <span>
                        <b>Address</b>
                      </span>{" "}
                      <br />
                      <span>{item?.message}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2 card-design">
                <div className="card-body">
                  <div className="d-flex gap-3">
                    <FaPhoneAlt
                      style={{ color: "#4798bd", fontSize: "20px" }}
                    />
                    <div
                      className="align-items-left"
                      style={{ textAlign: "left" }}
                    >
                      <span>
                        <b>Phone</b>
                      </span>{" "}
                      <br />
                      <span>{item?.phoneNumber1}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2 card-design">
                <div className="card-body">
                  <div className="d-flex gap-3">
                    <AiTwotoneMail
                      style={{ color: "#4798bd", fontSize: "20px" }}
                    />
                    <div
                      className="align-items-left"
                      style={{ textAlign: "left" }}
                    >
                      <span>
                        <b>Email</b>
                      </span>{" "}
                      <br />
                      <span>{item?.email}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <h3>
                  <b>Follow Us</b>
                </h3>
                <div className="contents">
                  <div className="icons">
                    <p>
                      <a
                        href="https://wa.me/8310330060"
                        target="_blank"
                        aria-label="Chat with us on WhatsApp"
                      >
                        <FaWhatsapp
                          style={{ fontSize: "30px", color: "#4798bd" }}
                        />
                      </a>
                    </p>
                  </div>
                  <div className="icons">
                    <p>
                      <a href="https://www.facebook.com/" target="_blank">
                        <FaFacebook
                          style={{ fontSize: "30px", color: "#4798bd" }}
                        />
                      </a>
                    </p>
                  </div>
                  <div className="icons">
                    <p>
                      <a href="https://www.instagram.com/" target="_blank">
                        <FaSquareInstagram
                          style={{ fontSize: "30px", color: "#4798bd" }}
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Contactus;
